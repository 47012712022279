import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";
import microPic from "../../images/micro.png";
import visualPic from "../../images/visual.png";
import auditPic from "../../images/audit.png";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";

const Container = tw.div`relative mb-3`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center`}
`;
const Heading = tw(
  SectionHeading
)`w-full text-3xl md:text-6xl lg:text-8xl pl-10`;
const SusHeading = tw(
  SectionHeading
)`text-xl md:text-2xl lg:text-3xl text-left pr-20`;
const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 flex`}
`;
const TitleContainer = tw.div`flex items-start justify-start text-left gap-3`;
const Title = tw.h5`mt-4 text-lg lg:text-2xl pl-2`;

const Card = styled.div`
  ${tw`flex flex-col mx-3 items-center border-2 border-solid border-main-2 rounded-lg mt-12`}
  .imageContainer {
    ${tw`text-left p-3 flex-shrink-0 relative`}
    img {
      ${tw`w-16 h-16 md:w-16 md:h-16 lg:h-32 lg:w-32`}
    }
  }
  .right {
    ${tw`order-3 -ml-12 lg:ml-0`}
  }
  .textContainer {
    ${tw`mt-2 text-center`}
  }

  .title {
    ${tw`mt-2 mx-2 font-bold text-xl leading-none text-black`}
  }

  .description {
    ${tw`mt-3 font-semibold text-black text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const PrimaryAction = tw.button`px-2 py-3 mt-10 text-base sm:text-lg lg:text-2xl sm:mt-16 sm:px-8 sm:py-4 bg-[#f8bd32] text-primary-500 font-bold rounded-full drop-shadow-2xl transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

export default ({
  cards = [
    {
      title: (
        <>
          <p tw="whitespace-nowrap">NGÀY HỌC</p>
          <p tw="whitespace-nowrap">MỖI TUẦN</p>
        </>
      ),
      description: (
        <>
          <TitleContainer>
            <Title tw="ml-3">‧</Title>
            <Title>
              Mỗi tuần sẽ có&nbsp;
              <span tw="font-black font-sans">3 ngày học</span>
              &nbsp;chính.
            </Title>
          </TitleContainer>
          <TitleContainer>
            <Title tw="ml-3">‧</Title>
            <Title>
              Học viên có thể học vào&nbsp;
              <span tw="font-black font-sans">T2, T4, T6</span>&nbsp;hoặc&nbsp;
              <span tw="font-black font-sans">T3, T5, T6</span>
            </Title>
          </TitleContainer>
          <TitleContainer>
            <Title tw="ml-3">‧</Title>
            <Title>
              T7 CN hàng tuần, học viên sẽ chuẩn bị&nbsp;
              <span tw="font-black font-sans">từ vựng trên website</span>
              &nbsp;
            </Title>
          </TitleContainer>
        </>
      ),
    },
    {
      title: (
        <>
          <p tw="whitespace-nowrap">CA HỌC</p>
          <p tw="whitespace-nowrap">MỖI NGÀY</p>
        </>
      ),
      description: (
        <>
          <TitleContainer>
            <Title tw="ml-3">‧</Title>
            <Title>
              Mỗi ngày học sẽ gồm&nbsp;
              <span tw="font-black font-sans	">4 ca</span>
              &nbsp;chính.
            </Title>
          </TitleContainer>
          <TitleContainer>
            <Title tw="ml-3">‧</Title>
            <Title>
              <span tw="font-black font-sans">Sáng:</span> 5:30 - 6:00/ 6:10 -
              6:40
              <p>
                <span tw="font-black font-sans	">Tối:</span> 20:30 - 21:00/ 21:30
                -22:00
              </p>
            </Title>
          </TitleContainer>

          <TitleContainer>
            <Title tw="ml-3">‧</Title>
            <Title>
              Học viên&nbsp;
              <span tw="font-black font-sans">
                không bị giới hạn số ca học&nbsp;
              </span>
              tham gia trong một ngày
            </Title>
          </TitleContainer>
        </>
      ),
    },
  ],
  specialNum = 4,
  action = {
    title: (
      <>
        Tiến bộ vượt trội chỉ sau&nbsp;
        <p tw="font-black font-sans text-2xl md:text-5xl lg:text-7xl">
          3 tháng
        </p>
      </>
    ),
    button: {
      href: '#',
      title: 'ĐĂNG KÝ HỌC',
    },
  },
  learningStyle = "Phương pháp học (LEARNING STYLE)",
  learningMethods = [
    {
      iconScr: microPic,
      method: "HỌC TẬP CHIA NHỎ (MICRO-LEARNING)",
      description: (
        <>
          Chia nhỏ nội dung học thành &nbsp;
          <span tw="font-black font-sans	">
            từng đợt ngắn và lặp đi lặp lại{" "}
          </span>
          &nbsp; giúp tăng cường khả năng ghi nhớ và tập trung tối đa.
        </>
      ),
      align: "left",
    },
    {
      iconScr: visualPic,
      method: "HỌC QUA HÌNH ẢNH (VISUAL LEARNING)",
      description: (
        <>
          Kích thích trí tưởng tượng, giúp ghi nhớ từ vựng dễ dàng bằng cách
          &nbsp;
          <span tw="font-black font-sans	">liên kết hình ảnh với từ vựng.</span>
          &nbsp; Từ đó, có thể từng bước&nbsp;
          <span tw="font-black font-sans	">
            hình thành khả năng phản xạ khi giao tiếp.
          </span>
          &nbsp;
        </>
      ),
      align: "right",
    },
    {
      iconScr: auditPic,
      method: "HỌC QUA ÂM THANH (AUDITORY LEARNING)",
      description: (
        <>
          Giúp bạn dễ dàng tiếp thu kiến thức mới,&nbsp;
          <span tw="font-black font-sans	">cải thiện khả năng nghe,</span>
          &nbsp;từ đó hoàn thiện phát âm và giao tiếp.
        </>
      ),
      align: "left",
    },
  ],
}) => {
  console.log(learningMethods, action);
  return (
    <Container id="when">
      <ThreeColumnContainer>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card tw="">
              <TitleContainer tw="items-center text-main-2  md:-mb-10">
                <Heading tw="basis-1/3 md:p-0">{specialNum}</Heading>
                <SusHeading tw="p-0">{card.title}</SusHeading>
              </TitleContainer>
              <span className="textContainer" tw="p-2">
                <p className="description">{card.description}</p>
              </span>
            </Card>
          </Column>
        ))}

        <Column tw="flex flex-col items-center mt-3 lg:mt-0 self-center">
          <Heading tw="text-main-2 p-0 text-xl md:text-4xl lg:text-6xl">
            {action.title}
          </Heading>
          <a href={action.button.href}>
              <PrimaryAction>{action.button.title}</PrimaryAction>
            </a>
        </Column>
      </ThreeColumnContainer>

      <Heading tw="text-main-2 text-2xl lg:text-5xl mt-6 pl-0 text-left">
        {learningStyle}
      </Heading>
      <Column tw="w-full">
        <Card tw="grow flex flex-col py-3 items-center gap-2">
          {learningMethods?.length > 0 &&
            learningMethods.map((item, index) => (
              <TitleContainer key={index} tw="items-center text-main-2">
                <span
                  className={`${
                    item.align !== "right"
                      ? "imageContainer"
                      : "imageContainer right"
                  }`}
                >
                  <img src={item.iconScr} />
                </span>
                <SusHeading tw="text-black px-10">
                  {item.method}
                  <Title tw="font-semibold">{item.description}</Title>
                </SusHeading>
              </TitleContainer>
            ))}
        </Card>
      </Column>
      <DecoratorBlob />
    </Container>
  );
};
