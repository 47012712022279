import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import logo from "../../images/logo.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";

const Container = tw(ContainerBase)`bg-gray-100 text-black -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8 scale-150`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw.a`border-b-2 self-start border-transparent hocus:text-gray-900 hocus:border-gray-900 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-2 flex`;
const Title = tw.h6`text-xs pt-3 tracking-tighter md:text-lg  mt-1 leading-snug text-base`;

const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-black hover:text-black transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const SocialLink2 = styled.div`
  ${tw`m-2`}
  .action {
    ${tw`text-center rounded-full inline-block w-full p-3 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline`}
  }
  .bg-main-0 {
    ${tw`bg-main-0 `}
  }
  .bg-main-1 {
    ${tw`bg-main-1 `}
  }
  .bg-main-2 {
    ${tw`bg-main-2 `}
  }
  .bg-main-3 {
    ${tw`bg-main-3 `}
  }
  .bg-main-4 {
    ${tw`bg-main-4 `}
  }
  i {
    font-size: 1.5rem;
    line-height: 1;
    ${tw`text-white`};
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-black`;
export default () => {
  return (
    <Container id="contact">
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src="https://drive.google.com/uc?export=view&id=1hKdjd0il_NayqRrSy8dA4FtyFkqtk8Ko" />
            <LogoText>Hương Mysheo</LogoText>
          </LogoContainer>
          <LinksContainer>
            <Link href="#">
              <SocialLinksContainer>
                <SocialLink2>
                  <a
                    href="https://www.facebook.com/huongmysheo"
                    class="action bg-main-0"
                  >
                    <i class="fa-solid fa-location-dot"></i>
                  </a>
                </SocialLink2>
                <Title>
                  121, Bạch Đằng, Phường 2, Tân Bình, Thành phố Hồ Chí Minh
                </Title>
              </SocialLinksContainer>
              <SocialLinksContainer>
                <SocialLink2>
                  <a
                    href="https://www.facebook.com/huongmysheo"
                    class="action bg-main-1"
                  >
                    <i class="fa-solid fa-envelope"></i>
                  </a>
                </SocialLink2>
                <Title>support@mysheo.com</Title>
              </SocialLinksContainer>
              <SocialLinksContainer>
                <SocialLink2>
                  <a
                    href="https://www.facebook.com/huongmysheo"
                    class="action bg-main-2"
                  >
                    <i class="fa-solid fa-phone-volume"></i>
                  </a>
                </SocialLink2>
                <Title> 0768074203</Title>
              </SocialLinksContainer>
            </Link>

            {/* <Link href="#">Chính sách học viên</Link> */}
            {/* <Link href="#">Contact Us</Link> */}
            {/* <Link href="#">Blog</Link> */}
            {/* <Link href="https://www.facebook.com/huongmysheo/reviews">Reviews</Link> */}
          </LinksContainer>

          <SocialLinksContainer>
            <SocialLink2>
              <a
                href="https://www.facebook.com/huongmysheo"
                class="action bg-main-4"
              >
                <i class="fa-brands fa-facebook"></i>
              </a>
            </SocialLink2>
            <SocialLink2>
              <a
                href="https://www.youtube.com/@huongmysheo288"
                class="action bg-main-1"
              >
                <i class="fa-brands fa-youtube"></i>
              </a>
            </SocialLink2>
            <SocialLink2>
              <a
                href="https://www.tiktok.com/@mysheoenglish"
                class="action bg-main-3"
              >
                <i class="fa-brands fa-tiktok"></i>
              </a>
            </SocialLink2>
          </SocialLinksContainer>
          <CopyrightText>
            <Link href="https://drive.google.com/file/d/1y68VcVIoeJZ8xy46irgQ16HSVZkv-RyG/view">
              Terms and Policies
            </Link>
            &copy; Hương Mysheo
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
