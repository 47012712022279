import { useEffect, useState, useRef } from "react";
import tw, { styled } from "twin.macro";

const DialogContainer = styled.div`
  ${tw`fixed inset-0 flex items-center w-full justify-center z-50`}
`;

const DialogContent = styled.div`
  ${tw`bg-white rounded-md shadow-lg p-6 w-full lg:max-w-md`}
`;

const DialogTitle = tw.h2`text-lg font-bold mb-4`;

const Container = tw.div`fixed inset-0 mt-128 h-5`;
const OpenButton = tw.button`bg-main-3 z-50 h-10 rounded-l-lg`;

const TeacherAvatar = styled.img`
  ${tw`w-16 h-16 rounded-full`}
`;

const TeacherName = tw.h3`text-xl font-bold mb-3`;
const Typography = tw.p`text-xl font-semibold`;
const Grid = tw.div`flex items-center `;
const Button = tw.button` mr-1 focus:outline-none`;
const Title = tw.h1`text-xl leading-none`;

const AdditionalComments = styled.textarea`
  ${tw`w-full h-24 rounded border border-gray-300 px-3 py-2 focus:outline-none focus:border-blue-500`}
`;

const GroundButton = tw.div`flex justify-end mt-6`;

const SkipButton = styled.button`
  ${tw`border rounded px-4 py-2 text-gray-600 disabled:opacity-50`}
`;

const SubmitButton = styled.button`
  ${tw`bg-blue-500 mx-3 text-white rounded px-4 py-2 disabled:opacity-50`}
`;

const StarIcon = ({ hoverValue, rating }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      strokeWidth={0.5}
      stroke="currentColor"
      style={{
        fill: hoverValue >= rating ? "#FFD700" : "#A9A9A9",
        width: "24px",
        height: "24px",
      }}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
      />
    </svg>
  );
};

export const FeedbackDialog = () => {
  const [open, setOpen] = useState(false);
  const [hoverValue, setHoverValue] = useState(0);
  const dialogRef = useRef(null);

  const handleRatingHover = (rating) => {
    console.log(rating);
    setHoverValue(rating);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    window.location = "/chuyen-kenh-phan-xa";
  };

  const handleRatingClick = (rating) => {
    handleRatingHover(rating);
  };
  const handleSkip = () => {
    setOpen(false);
  };

  return (
    <>
      {!open && (
        <Container dir="rtl" style={{ zIndex: "10000" }}>
          <OpenButton onClick={handleOpen}>
            <Title style={{ color: "white" }}>&ensp; 7.7 ƯU ĐÃI NGÀY ĐÔI</Title>
          </OpenButton>
        </Container>
      )}
      {open && (
        <DialogContainer>
          <DialogContent>
            {/* <form> */}
            <DialogTitle>
              <Typography>7.7 ƯU ĐÃI NGÀY ĐÔI</Typography>
            </DialogTitle>
            <DialogContent>
              {/* <div> */}
              <TeacherName>KHÓA CHUYỂN KÊNH PHẢN XẠ</TeacherName>
              <Title>Học mỗi ngày xuyên suốt 1 tháng</Title>
              <br />
              <Title>Tặng 7 suất giảm 100% học phí</Title>
              <br />
              <Title>Tặng 77 deal giá 1.777k</Title>
              {/* <Grid >
            {[1, 2, 3, 4, 5].map((rating) => (
              <Button
                key={rating}
                tw="mr-1 focus:outline-none"
                onMouseEnter={() => handleRatingHover(rating)}
                onClick={() => handleRatingClick(rating)}
              >
                <StarIcon hoverValue={hoverValue} rating={rating} />
                <p>{rating}</p>
              </Button>
            ))}
          </Grid> */}
              {/* <AdditionalComments
            placeholder="Additional Comments..."
            name="content"
          ></AdditionalComments> */}
              {/* </div> */}
            </DialogContent>
            <GroundButton>
              <SkipButton onClick={handleSkip}>Bỏ qua</SkipButton>
              <SubmitButton onClick={handleClose}>Tìm hiểu thêm</SubmitButton>
            </GroundButton>
            {/* </form> */}
          </DialogContent>
        </DialogContainer>
      )}
    </>
  );
};
