import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/LP2.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/LP2.js";
import SliderCard from "components/cards/ThreeColSlider.js";
import TrendingCard from "components/cards/TwoTrendingPreviewCardsWithImage.js";
import Blog from "components/blogs/PopularAndRecentBlogPosts.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import FAQ2 from "components/faqs/SingleCol.js";
import tw, { styled } from "twin.macro";
import SimpleContactUs from "components/forms/SimpleContactUs";
import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import Header, {
  LogoLink,
  NavLinks,
  NavLink as NavLinkBase,
} from "components/headers/light.js";
import KickUpCourseImageSrc from "images/KickUpCourse.png";
import Hero2 from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import ResponsiveVideoEmbed from "../helpers/ResponsiveVideoEmbed";
import Hero3 from "components/hero/BackgroundMysheo";
import FooterWithForm from "components/footers/FooterWithForm";
import FaqsWithMysheo from "components/faqs/FaqsWithMysheo";
import ThreeColWithTwoBlock from "components/features/ThreeColWithTwoBlock";

//icon list
import visualPic from "../images/visual.png";
import auditPic from "../images/audit.png";
import microPic from "../images/micro.png";
import { ReactComponent as ChatIcon } from "../images/chat-bubble.svg";
import { ReactComponent as Square3DIcon } from "../images/square-3-stack-3d.svg";
import { ReactComponent as DesptopIcon } from "../images/computer-desktop.svg";

const Subheading = tw.span`uppercase tracking-widest font-bold text-main-2`;
const HighlightedText = tw.span`text-main-2`;
const TitleContainer = tw.div`flex items-start justify-start text-left gap-3`;
const Title1 = tw.h5`mt-4 text-lg lg:text-2xl pl-2`;
const Title = tw.h1`text-2xl text-black font-black leading-none`;

const ContentVideo = tw.div`relative flex flex-col items-center`;
const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  iframe {
    ${tw`max-w-screen-xl`}
    height: '50%';
    weight: "50%";
  }
`;

const NavLink = tw(NavLinkBase)`
  sm:text-sm sm:mx-6
  whitespace-nowrap
`;

const P = tw.p`text-main-2 mb-4 text-xs sm:text-5xl leading-tight`;

export default () => (
  <AnimationRevealPage class="scroll-smooth">
    <Hero3
      sheetName="Mã code FS15"
      LP="LP3"
      courseDefault="CKPX4-3m"
      hasDiscountCode={false}
      mainTitle={
        <>
          <p tw="text-left whitespace-nowrap">Chuyển kênh phản xạ</p>
          <p tw="text-left whitespace-nowrap">Học 4 ngày/ tuần</p>
        </>
      }
      subTitle1="Chuyển kênh tư duy"
      subTitle2="Ưu đãi 50% chỉ còn 300.000"
      wrapContent={[
        {
          title: "Đều đặn duy trì thói quen",
          highlined: "",
        },
        {
          title: "học Tiếng Anh",
          highlined: "MỖI NGÀY",
        },
      ]}
    />
    <ThreeColWithTwoBlock
      cards={[
        {
          title: (
            <>
              <p tw="whitespace-nowrap">NGÀY HỌC</p>
              <p tw="whitespace-nowrap">MỖI TUẦN</p>
            </>
          ),
          description: (
            <>
              <TitleContainer>
                <Title1 tw="ml-3">‧</Title1>
                <Title1>
                  Mỗi tuần sẽ có&nbsp;
                  <span tw="font-black font-sans">3 ngày học</span>
                  &nbsp;chính.
                </Title1>
              </TitleContainer>
              <TitleContainer>
                <Title1 tw="ml-3">‧</Title1>
                <Title1>
                  Học viên có thể học vào&nbsp;
                  <span tw="font-black font-sans">T2, T4, T6</span>
                  &nbsp;hoặc&nbsp;
                  <span tw="font-black font-sans">T3, T5, T6</span>
                </Title1>
              </TitleContainer>
              <TitleContainer>
                <Title1 tw="ml-3">‧</Title1>
                <Title1>
                  Tối T7 hàng tuần, học viên sẽ được tham gia&nbsp;
                  <span tw="font-black font-sans">
                    Talkshow do Mysheo đứng lớp
                  </span>
                  &nbsp;
                </Title1>
              </TitleContainer>
            </>
          ),
        },
        {
          title: (
            <>
              <p tw="whitespace-nowrap">CA HỌC</p>
              <p tw="whitespace-nowrap">MỖI NGÀY</p>
            </>
          ),
          description: (
            <>
              <TitleContainer>
                <Title1 tw="ml-3">‧</Title1>
                <Title1>
                  Mỗi ngày học sẽ gồm&nbsp;
                  <span tw="font-black font-sans	">4 ca</span>
                  &nbsp;chính.
                </Title1>
              </TitleContainer>
              <TitleContainer>
                <Title1 tw="ml-3">‧</Title1>
                <Title1>
                  <span tw="font-black font-sans">Sáng:</span> 5:30 - 6:00/ 6:10
                  - 6:40
                  <p>
                    <span tw="font-black font-sans	">Tối:</span> 20:30 - 21:00/
                    21:30 -22:00
                  </p>
                </Title1>
              </TitleContainer>

              <TitleContainer>
                <Title1 tw="ml-3">‧</Title1>
                <Title1>
                  Học viên&nbsp;
                  <span tw="font-black font-sans">
                    không bị giới hạn số ca học&nbsp;
                  </span>
                  tham gia trong một ngày
                </Title1>
              </TitleContainer>
            </>
          ),
        },
      ]}
      specialNum={4}
      learningStyle="Phương pháp học (LEARNING STYLE)"
      learningMethods={[
        {
          iconScr: microPic,
          method: "HỌC TẬP CHIA NHỎ (MICRO-LEARNING)",
          description: (
            <>
              Chia nhỏ nội dung học thành &nbsp;
              <span tw="font-black font-sans	">
                từng đợt ngắn và lặp đi lặp lại{" "}
              </span>
              &nbsp; giúp tăng cường khả năng ghi nhớ và tập trung tối đa.
            </>
          ),
          align: "left",
        },
        {
          iconScr: visualPic,
          method: "HỌC QUA HÌNH ẢNH (VISUAL LEARNING)",
          description: (
            <>
              Kích thích trí tưởng tượng, giúp ghi nhớ từ vựng dễ dàng bằng cách
              &nbsp;
              <span tw="font-black font-sans	">
                liên kết hình ảnh với từ vựng.
              </span>
              &nbsp; Từ đó, có thể từng bước&nbsp;
              <span tw="font-black font-sans	">
                hình thành khả năng phản xạ khi giao tiếp.
              </span>
              &nbsp;
            </>
          ),
          align: "right",
        },
        {
          iconScr: auditPic,
          method: "HỌC QUA ÂM THANH (AUDITORY LEARNING)",
          description: (
            <>
              Giúp bạn dễ dàng tiếp thu kiến thức mới,&nbsp;
              <span tw="font-black font-sans	">cải thiện khả năng nghe,</span>
              &nbsp;từ đó hoàn thiện phát âm và giao tiếp.
            </>
          ),
          align: "left",
        },
      ]}
    />
    <FaqsWithMysheo
      contents={[
        {
          title: (
            <>
              Người đi làm&nbsp;<span tw="font-black">bận rộn,</span>
              &nbsp;cần lộ trình&nbsp;
              <sanp tw="font-black">học online linh hoạt.</sanp>&nbsp;
            </>
          ),
          icon: Square3DIcon,
        },
        {
          title: (
            <>
              Người cần nâng cao trình độ giao tiếp tiếng Anh để&nbsp;
              <span tw="font-black">phục vụ cho công việc.</span>
            </>
          ),
          icon: DesptopIcon,
        },
        {
          title: (
            <>
              Người&nbsp;<span tw="font-black">muốn tăng phản xạ</span>
              &nbsp;hoặc đã có nền tảng tiếng Anh cơ bản nhưng&nbsp;
              <sanp tw="font-black">chưa giao tiếp được.</sanp>&nbsp;
            </>
          ),
          icon: ChatIcon,
        },
      ]}
    />

    {/* <Hero2
      heading="Khóa Chuyển Kênh Phản Xạ (4 ngày/ tuần)"
      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam."
      imageSrc="https://drive.google.com/uc?export=view&id=1NvTxKkW6NXAyGIRVc7Tui2wO3gSByAds"
      imageDecoratorBlob={true}
      primaryButtonUrl="https://google.com"
      primaryButtonText="LP3, LP3, LP3."
      buttonRounded={true}
      features={[
        "Khóa học này dành cho ai?",
        "Người đi làm bận rộn, cần lộ trình học online linh hoạt ",
        "Người mất gốc Tiếng Anh hoặc đã có nền tảng tiếng Anh cơ bản nhưng chưa giao tiếp được.",
        "Người sắp ứng tuyển hoặc đang làm việc trong môi trường đa quốc gia, cần nâng trình giao tiếp nhanh chóng",
      ]}
      testimonial={{
        quote:
          "Bạn sẽ nhanh nhạy trong suy nghĩ và phản ứng bằng tiếng Anh; chuyển từ tư duy bị động sang chủ động khi giao tiếp; đặc biệt, bạn sẽ duy trì thói quen giao tiếp Tiếng Anh mà không cần quá nhiều động lực; từ đó, cải thiện toàn diện 4 kỹ năng: Nghe, Nói, Đọc, Viết",
        customerName: "CẢI THIỆN SAU KHÓA HỌC", //Charlotte Hale",
        //customerCompany: "Founder", //"Delos Inc.",
      }}
      LP3={true}
    /> */}
    {/* <Pricing
      heading="This is LP3"
      description="LP3 LP3 content"
      plans={[
        {
          name: "LP3",
          price: ["500.000", "/ khóa"],
          oldPrice: "",
          description:
            "Mất gốc, thiếu từ vựng; Ngại giao tiếp, chưa dám nói; Cần xây dựng nền tảng phản xạ.",
          features: [
            "Level: Cơ Bản",
            "Thứ 2 - thứ 6:  6h10 - 6h40 / 21h00 - 21h30 / 22h10 - 22h40; Thứ 7: 21h00 - 21h30",
            "15 - 20 học viên",
            "Topic: At the cinema , At my place ",
            "Học tối đa 2 ca",
            "Có buổi thực hành riêng 2 ngày/tuần",
          ],
          url: "#info",
        },
        {
          name: "Chuyển Kênh Phản Xạ",
          price: ["700.000", "/ khóa"],
          oldPrice: "",
          description:
            "Đã có từ vựng cơ bản; Đã giao tiếp cơ bản; Mong muốn chuyển kênh tư duy tiếng Anh, nâng trình phản xạ.",
          features: [
            "Level: Trung Cấp",
            "Thứ 2 - thứ 6: 5h30 - 6h00 / 6h10 - 6h40 / 20h00 - 20h30 / 21h30 - 22h00 / 22h10 - 22h40; Thứ 7: 21h00 - 21h30",
            "Dưới 15 học viên",
            "Topic: Clothes, Friends ",
            "Học tối đa 3 ca",
          ],
          url: "#info",
          // featured: "Tăng tốc"
        },
      ]}
    /> */}
    {/* <MainFeature className="h-screen"/> */}
    {/* <Features /> */}
    {/* <SliderCard /> */}
    {/* <TrendingCard /> */}
    {/* <Blog /> */}
    {/* <Testimonial textOnLeft={true}/> */}
    {/* <FAQ
      subheading=""
      headings={["LP3 Giải đáp về khóa học", "LP3 Giải đáp về khóa học"]}
      descriptions={["LP3 Dưới đây là những câu hỏi được hỏi quan tâm nhiều nhất về khóa học.", "Dưới đây là những câu hỏi được hỏi quan tâm nhiều nhất về khóa học."]}
      showFaqs={false}
    /> */}
    <FAQ2
      descriptions={[
        <Title>Dưới đây là những câu hỏi thường gặp về khóa học</Title>,
      ]}
      subheading={<Subheading>Hương Mysheo</Subheading>}
      heading={
        <>
          Giải đáp <HighlightedText>thắc mắc </HighlightedText>
        </>
      }
      faqs={[
        {
          question: "Tôi có được thay đổi khung giờ học trong ngày không?",
          answers: [
            "Có.",
            "Học viên được tham gia 3 - 4 ca học mỗi ngày. Nội dung các ca học trong ngày giống nhau. Học viên có thể linh hoạt đặt lịch (book) các khung giờ học khác nhau tùy theo nhu cầu.",
          ],
        },
        {
          question:
            "Sĩ số một lớp dưới 15 học viên trong 30 phút thì có đảm bảo chất lượng không ?",
          answers: [
            "Lớp học thực tế thường có sĩ số rơi vào tầm 10-11 bạn.",
            "Mysheo vẫn được đảm bảo chất lượng giảng dạy vì thực chất thời gian học của học viên diễn ra trong 60 phút: ",
            "15 phút trước buổi học, học viên được cung cấp trước nội dung bài học và làm bài tập ngay trên website để ôn luyện từ vựng mới.",
            "30 phút học, ngoài bài giảng, học viên tham gia các hoạt động thực hành với giáo viên và thảo luận theo cặp.",
            "15 phút sau buổi học, học viên tiếp tục làm bài tập và được chấm chữa bài writing 1 - 1.",
            "Học viên có thể xem lại video recording sau buổi học để ôn tập",
            "=> Phương pháp giảng dạy không liên quan đến việc số lượng học viên nhiều hay ít, vẫn đảm bảo sĩ số dưới 15 để nâng cao chất lượng học tập của học viên. ",
          ],
        },
        {
          question: "Đội ngũ giáo viên và giáo trình giảng dạy ?",
          answers: [
            "Hương Mysheo sẽ không tham gia giảng dạy. Đặc biệt học viên sẽ được học với đội ngũ giáo viên của Hương Mysheo (có cả Giáo viên Việt Nam và Giáo viên Nước Ngoài) rất trẻ, năng động và được đào tạo chuyên nghiệp. Mỗi giáo viên sẽ có style riêng nên học viên sẽ có trải nghiệm học tập phong phú hơn. Đặc biệt: học viên chủ động lựa chọn giờ học nên có thể lựa chọn giảng viên phù hợp với mình",
            "Giáo trình giảng dạy do chính Hương Mysheo biên soạn nên nội dung hoàn toàn được dựa trên các cuộc hội thoại thực tế, với các chủ đề gần gũi xoay quanh cuộc sống, công việc hàng ngày. Học viên sẽ được học cách dùng từ và diễn đạt ý như một người bản xứ. ",
          ],
        },
        {
          question: "Khóa học có cam kết đầu ra hay không ?",
          answers: [
            "Cam kết luôn đến từ 2 phía nên mang tính tương đối.",
            "Hương Mysheo luôn chú trọng vào kết quả và sự thay đổi của học viên sau 1 tháng. Giáo trình giảng dạy luôn đảm bảo học viên sẽ tăng khả năng phản xạ, giúp học viên tự tin hơn trong giao tiếp. Sau 1 tháng, học viên có thể nhận thấy sự thay đổi rõ rệt về khả năng phản xạ khi giao tiếp.",
          ],
        },
      ]}
    />
    {/* <ContentVideo>
      <ResponsiveVideoEmbed url={url} />
      <Title>Feedback của học viên</Title>
    </ContentVideo> */}
    {/* <SimpleContactUs
      sheetName="LP3"
      LP="LP3"
      courseDefault="CKPX4-3m"
      hasDiscountCode={true}
      // content="ƯU ĐÃI 100% HỌC PHÍ KHÓA CHUYỂN KÊNH PHẢN XẠ.
      // Săn ưu đãi ngay! Duy nhất vào 21h - 22h ngày 07/07"
      content={
        <>
          Hãy để lại thông tin tư vấn bên dưới. <wbr />
          <br />
          Chốt danh sách khi đủ số lượng học viên.
        </>
      }
      countDown={true}
      startTime={"2023-07-07T21:00:00+07:00"}
    /> */}
    <FooterWithForm />
  </AnimationRevealPage>
);
