import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/LP2.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/LP2.js";
import SliderCard from "components/cards/ThreeColSlider.js";
import TrendingCard from "components/cards/TwoTrendingPreviewCardsWithImage.js";
import Blog from "components/blogs/PopularAndRecentBlogPosts.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import FAQ2 from "components/faqs/SingleCol.js";
import tw from "twin.macro";
import SimpleContactUs from "components/forms/SimpleContactUs";
import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import Hero2 from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";


const Subheading = tw.span`uppercase tracking-widest font-bold text-main-2`;
const HighlightedText = tw.span`text-main-2`;
const Title = tw.h1`text-2xl text-black font-black leading-none`;

export default () => (
  <AnimationRevealPage class="scroll-smooth">
    {/* <FeedbackDialog /> */}
    <Hero 
      url="https://www.youtube.com/embed/RA9gJQpN6PY" 
      // showImage={true}  
    />
    
    <Pricing />
    {/* <MainFeature className="h-screen"/> */}
    {/* <Features /> */}
    {/* <SliderCard /> */}
    {/* <TrendingCard /> */}
    {/* <Blog /> */}
    {/* <Testimonial textOnLeft={true}/> */}
    {/* <FAQ
      subheading=""
      heading="Giải đáp về khóa học"
      description="Dưới đây là những câu hỏi được hỏi quan tâm nhiều nhất về khóa học."
      faqs={[
        {
          question: "Khóa học giúp học viên những gì?",
          answers: [
            "Luyện phản xạ, xây dựng thói quen giao tiếp Tiếng Anh hàng ngày.",
            "Tạo môi trường giao tiếp thực tế, tương tác sôi nổi cùng giảng viên và bạn học.",
            "Luyện tập toàn diện 4 kỹ năng: Nghe, Nói, Đọc, Viết.",
            "Trau dồi vốn từ vựng xoay quanh các chủ đề thực tế hàng ngày.",
            "Được thực hành chấm chữa bài Writing 1-1 sau mỗi buổi học.",
          ],
        },
        {
          question: "Khóa học dành riêng cho những ai?",
          answers: [
            "Học viên đã biết Tiếng Anh cơ bản nhưng từ vựng chưa phong phú, thường xuyên ấp úng, ngập ngừng và bí từ khi giao tiếp tiếng Anh.",
            "Học viên đã lâu chưa sử dụng lại Tiếng Anh.",
            "Học viên mất gốc, cần xây lại kiến thức nền tảng.",
          ],
        },
        {
          question: "Tôi có được thay đổi khung giờ học không? ",
          answers: [
            "Có. Học viên được đặt lịch học mỗi ngày (booking) với các lớp được dẫn dắt bởi đội ngũ giáo viên Mysheo. ",
            "Mỗi ngày, học viên được tham gia 1 hoặc cả 3 ca học. Nội dung các ca học trong ngày giống nhau.",
            "Học viên có thể linh hoạt đặt lịch (book) các khung giờ học khác nhau mỗi ngày tùy theo nhu cầu.",
          ],
        },
        {
          question: "Lộ trình học và phương pháp học?",
          answers: [
            "Lộ trình học 1 tháng với các chủ đề xoay quanh cuộc sống hàng ngày.",
            "Phương pháp học: Học tập chia nhỏ (Micro-learning); Học qua hình ảnh (Visual Learning); Học qua âm thanh (Auditory Learning); Học từ vựng qua Quiz và Flashcard.",
          ],
        },
        {
          question: "Khóa Chuyển Kênh Phản Xạ có gì khác biệt?",
          answers: [
            "Có đến 5 khung giờ học một ngày để học viên lựa chọn.",
            "Trình độ Chuyển kênh phản xạ level 2 (Nền Tảng Phản Xạ - level 1) tập trung vào chuyển kênh tư duy để tăng cường phản xạ (có thể nói nhiều ý, phản xạ tự nhiên hơn,..)",
          ],
        },
      ]}
    /> */}
    <FAQ2
      descriptions={[
        <Title></Title>,
        <Title>Các câu hỏi được quan tâm nhiều nhất</Title>,
      ]}
      subheading={<Subheading>Hương Mysheo</Subheading>}
      heading={
        <>
          Giải đáp <HighlightedText>thắc mắc </HighlightedText>
        </>
      }
      faqs={[

        {
          question: "Tôi có được thay đổi khung giờ học trong ngày không?",
          answers: [
            "Có.",
            "Học viên được tham gia 3 - 4 ca học mỗi ngày. Nội dung các ca học trong ngày giống nhau. Học viên có thể linh hoạt đặt lịch (book) các khung giờ học khác nhau tùy theo nhu cầu.",
          ],
        },
        {
          question:
            "Sĩ số một lớp dưới 15 học viên trong 30 phút thì có đảm bảo chất lượng không ?",
          answers: [
            "Lớp học thực tế thường có sĩ số rơi vào tầm 10-11 bạn.",
            "Mysheo vẫn được đảm bảo chất lượng giảng dạy vì thực chất thời gian học của học viên diễn ra trong 60 phút: ",
            "15 phút trước buổi học, học viên được cung cấp trước nội dung bài học và làm bài tập ngay trên website để ôn luyện từ vựng mới.",
            "30 phút học, ngoài bài giảng, học viên tham gia các hoạt động thực hành với giáo viên và thảo luận theo cặp.",
            "15 phút sau buổi học, học viên tiếp tục làm bài tập và được chấm chữa bài writing 1 - 1.",
            "Học viên có thể xem lại video recording sau buổi học để ôn tập",
            "=> Phương pháp giảng dạy không liên quan đến việc số lượng học viên nhiều hay ít, vẫn đảm bảo sĩ số dưới 15 để nâng cao chất lượng học tập của học viên. ",
          ],
        },
        {
          question: "Đội ngũ giáo viên và giáo trình giảng dạy ?",
          answers: [
            "Hương Mysheo sẽ không tham gia giảng dạy cả 2 khóa. Đặc biệt học viên sẽ được học với đội ngũ giáo viên của Hương Mysheo rất trẻ, năng động và được đào tạo chuyên nghiệp. Mỗi giáo viên sẽ có style riêng nên học viên sẽ có trải nghiệm học tập phong phú hơn. Đặc biệt: học viên chủ động lựa chọn giờ học nên có thể lựa chọn giảng viên phù hợp với mình. ",
            "Giáo trình giảng dạy do chính Hương Mysheo biên soạn. Điểm đặc biệt: nội dung hoàn toàn được dựa trên các cuộc hội thoại thực tế, với các chủ đề gần gũi xoay quanh cuộc sống, công việc hàng ngày. Học viên sẽ được học cách dùng từ và diễn đạt ý như một người bản xứ.",
          ],
        },
        {
          question: "Phương pháp học là những phương pháp nào ?",
          answers: [
            "Phương pháp Học tập chia nhỏ (Micro-learning) chia nhỏ nội dung học thành từng đợt ngắn và lặp đi lặp lại giúp tăng cường khả năng ghi nhớ và tập trung tối đa. ",
            "Phương pháp Học qua hình ảnh (Visual Learning) kích thích trí tưởng tượng, giúp bạn ghi nhớ từ vựng dễ dàng bằng cách liên kết hình ảnh với từ vựng. Từ đó, bạn có thể từng bước hình thành khả năng phản xạ khi giao tiếp.",
            "Phương pháp Học qua âm thanh (Auditory Learning) giúp bạn dễ dàng tiếp thu kiến thức mới, cải thiện khả năng nghe, từ đó hoàn thiện phát âm và giao tiếp.",
          ],
        },
        {
          question: "Khóa học có cam kết đầu ra hay không ?",
          answers: [
            "Mysheo cam kết đầu ra sau 3 tháng theo lộ trình.",
            "Học viên tham gia 95% khóa học sẽ được Mysheo test trực tiếp 1-1 sau 3 tháng, cam kết hỗ trợ học lại nếu chưa đạt kết quả.",
          ],
        },
      ]}
    />
    <SimpleContactUs
      sheetName="LP2"
      LP ='LP2'
      hasDiscountCode={true}
      content="Đăng ký tư vấn ngay bên dưới."
    />
    {/* <SubscribeNewsLetterForm /> */}
    <Footer />
  </AnimationRevealPage>
);
