import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { sheetApi } from "api/sheet-api";
import { LoadingComponent } from "LoadingComponent";
import { CountDown } from "CountDown";
import { DialogMessage } from "../../DialogMessage";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-main-2 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-2xl sm:text-3xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const InputFlexContainer = tw.div`relative flex justify-around items-start mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = styled.input`
  ${tw`p-2 rounded-md outline-none`}
  ${({ required, value }) => required && !value && tw`border-red-500`}
`;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-main-2 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;
const Description = tw.p`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const CustomSelect = styled.select`
  ${tw`w-full bg-transparent text-white text-base font-medium tracking-wide border-b-2 py-2 focus:outline-none transition duration-200`}
  option {
    ${tw`bg-white text-black`}
  }
  ${({ required, value }) => required && !value && tw`border-red-500`}
`;
const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`;

export default ({
  sheetName,
  hasDiscountCode = false,
  content,
  courseDefault,
  coursesType,
  countDown = false,
  startTime = undefined,
  LP = undefined,
}) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
    email: "",
    paymentStatus: "",
    howDidYouHearAboutUs: "",
    learningGoal: "",
    discountCode: "",
    course: courseDefault || "",
    selectDate: '',
  });

  const successMessage =
    "Hương Mysheo sẽ kiểm tra lại thanh toán! Mail xác nhận thanh toán thành công sẽ có sau 24 tiếng, học viên check mail để login vào website học tập. Nếu trong 48 giờ, học viên chưa nhận được mail xác nhận, vui lòng gửi mail về Support@mysheo.com để được hỗ trợ!.";

  const onSubmit = async (e) => {
    e.preventDefault();
    const {
      name,
      phone,
      email,
      paymentStatus,
      howDidYouHearAboutUs,
      learningGoal,
      discountCode,
      course,
      selectDate,
    } = formValues;

    console.log('selectDate' , formValues);

    if (startTime) {
      const start = new Date(startTime);
      const currentTime = new Date();
      if (currentTime < start) {
        setMessage("Chương trình chưa bắt đầu, vui lòng quay lại sau.");
        setIsOpen(true);
        return;
      }
    }

    if (
      !name ||
      !phone ||
      !email ||
      !paymentStatus ||
      !learningGoal ||
      !howDidYouHearAboutUs ||
      !course
    ) {
      setMessage("Vui lòng kiểm tra lại và điền đầy đủ thông tin chi tiết");
      setIsOpen(true);
      return;
    }

    if(!selectDate && LP === 'LP3'){
      setMessage('Vui lòng chọn lớp học phù hợp với bạn!')
      setIsOpen(true);
      return;
    }

    try {
      setLoading(true);
      const user = formValues;
      await sheetApi.registerCourse(
        { ...user, landingPage: sheetName },
        "Raw LP"
      );
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "new_subscriber",
        formLocation: "footer",
      });
      setLoading(false);
      setMessage(successMessage);
      setIsOpen(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setMessage("Đã có lỗi xảy ra, xin vui lòng thử lại sau!");
      setIsOpen(true);
    }
  };

  const defaultCoursesType = [
    // { course: "Nền Tảng Phản Xạ - 1 tuần (Level Cơ Bản)", value: "NTPX-1w" },
    { course: "KEM11 - 1 tháng", value: "KEM11-1m" },
    // },
    { course: "Chuyển Kênh Phản Xạ - 1 tháng", value: "CKPX-1m" },
    { course: "Chuyển Kênh Phản Xạ - 3 tháng", value: "CKPX-3m" },
    { course: "Tự Tin Phản Xạ - 4 tháng", value: "TTPX-4m" },
  ];

  if (!coursesType) coursesType = defaultCoursesType;

  return (
    <Container id="registrationForm">
      <LoadingComponent loading={loading}>
        <Content>
          <FormContainer>
            <div tw="mx-auto max-w-4xl">
              <h2>{content}</h2>
              <form action="#">
                <TwoColumn>
                  <Column>
                    <InputContainer>
                      <Label htmlFor="name-input">Tên của bạn</Label>
                      <Input
                        id="name-input"
                        type="text"
                        name="name"
                        placeholder=""
                        value={formValues.name}
                        onChange={(e) =>
                          setFormValues({ ...formValues, name: e.target.value })
                        }
                        required
                      />
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="email-input">Email</Label>
                      <Input
                        id="email-input"
                        type="email"
                        name="email"
                        placeholder=""
                        value={formValues.email}
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            email: e.target.value,
                          })
                        }
                        required
                      />
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="phone-input">Số điện thoại</Label>
                      <Input
                        id="phone-input"
                        type="tel"
                        name="phone"
                        placeholder=""
                        value={formValues.phone}
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            phone: e.target.value,
                          })
                        }
                        required
                      />
                    </InputContainer>

                    <InputContainer>
                      <Description htmlFor="howDidYouHearAboutUs">
                        Bạn biết đến khóa học này từ đâu?
                      </Description>
                      <CustomSelect
                        id="howDidYouHearAboutUs"
                        type="text"
                        name="howDidYouHearAboutUs"
                        placeholder=""
                        value={formValues.howDidYouHearAboutUs}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            howDidYouHearAboutUs: e.target.value,
                          });
                        }}
                        required
                      >
                        <option value=""> </option>
                        <option value="Facebook">Facebook</option>
                        <option value="Tiktok">Tiktok</option>
                        <option value="Youtube">Youtube</option>
                        <option value="Giới thiệu từ bạn bè, người thân">
                          Giới thiệu từ bạn bè. người thân
                        </option>
                        <option value="Khác">khác</option>
                      </CustomSelect>
                    </InputContainer>
                    {!courseDefault && (
                      <>
                        <InputContainer>
                          <Label htmlFor="course">Khoá học bạn đăng ký ?</Label>
                        </InputContainer>
                        <CustomSelect
                          id="course"
                          type="text"
                          name="course"
                          placeholder=""
                          value={formValues.course}
                          onChange={(e) => {
                            setFormValues({
                              ...formValues,
                              course: e.target.value,
                            });
                          }}
                          required
                        >
                          <option value=""> </option>
                          {coursesType.map((courseType) => (
                            <option value={courseType.value}>
                              {courseType.course}
                            </option>
                          ))}
                        </CustomSelect>
                      </>
                    )}
                    {/* Use for GA-tag  */}
                    <Input
                      tw="mx-auto max-w-4xl"
                      id="GA-tag"
                      type="text"
                      disabled
                      style={{ border: "none" }}
                    />
                  </Column>
                  <Column>
                    <InputContainer>
                      <Description htmlFor="learningGoal">
                        Mục tiêu học Tiếng Anh
                      </Description>
                      <CustomSelect
                        id="learningGoal"
                        type="text"
                        name="learningGoal"
                        placeholder=""
                        value={formValues.learningGoal}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            learningGoal: e.target.value,
                          });
                        }}
                        required
                      >
                        <option value=""> </option>
                        <option value="Mục đích học tập tại trường">
                          Mục đích học tập tại trường
                        </option>
                        <option value="Đọc, viết tài liệu công việc">
                          Đọc, viết tài liệu công việc
                        </option>
                        <option value=" Luyện thi TOEIC, PTE, IELTS để du học">
                          Luyện thi TOEIC, PTE, IELTS để du học
                        </option>
                        <option value="Luyện thi chứng chỉ để định cư">
                          Luyện thi chứng chỉ để định cư{" "}
                        </option>
                        <option value="Phỏng vấn xin việc">
                          Phỏng vấn xin việc{" "}
                        </option>
                        <option value="Cơ hội thăng tiến trong công việc">
                          Cơ hội thăng tiến trong công việc{" "}
                        </option>
                        <option value="Phát triển cá nhân và tự tin bản thân	">
                          Phát triển cá nhân và tự tin bản thân
                        </option>
                        <option value="Đồng hành cùng con học Tiếng Anh">
                          Đồng hành cùng con học Tiếng Anh
                        </option>
                        <option value="Giao tiếp trong công việc với bạn bè, đồng nghiệp, đối tác">
                          Giao tiếp trong công việc với bạn bè, đồng nghiệp, đối
                          tác
                        </option>
                        <option value="khác">khác</option>
                      </CustomSelect>
                    </InputContainer>

                    {LP === "LP3" && (
                      <InputContainer>
                        <Label htmlFor="selectDate">
                          Lớp học bạn đăng ký (LỚP 2-4-6 hoặc LỚP 3-5-6)
                        </Label>
                        <CustomSelect
                          id="selectDate"
                          type="text"
                          name="selectDate"
                          placeholder=""
                          value={formValues.selectDate}
                          onChange={(e) => {
                            setFormValues({
                              ...formValues,
                              selectDate: e.target.value,
                            });
                          }}
                        >
                          <option value=""> </option>
                          <option value="LỚP 2-4-6">
                            LỚP 2-4-6
                          </option>
                          <option value="LỚP 3-5-6">
                            LỚP 3-5-6
                          </option>
                        </CustomSelect>
                      </InputContainer>
                    )}

                    <InputContainer>
                      <Label htmlFor="paymentStatus">
                        Trạng thái thanh toán
                      </Label>
                    </InputContainer>
                    <InputFlexContainer>
                      <Input
                        id="paid"
                        type="radio"
                        name="paymentStatus"
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            paymentStatus: "Paid",
                          })
                        }
                        tw="ml-32"
                        required
                      />
                      <Label for="paid">Đã thanh toán</Label>
                    </InputFlexContainer>
                    <InputFlexContainer>
                      <Input
                        id="unpaid"
                        type="radio"
                        name="paymentStatus"
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            paymentStatus: "Unpaid",
                          })
                        }
                        tw="ml-32"
                      />
                      <Label for="unPaid">Chưa thanh toán</Label>
                    </InputFlexContainer>
                    <br />
                    <h5>
                      Nội dung chuyển khoản: Tên - sdt (Ví dụ: Quynh Nhu -
                      0394262819)
                    </h5>
                    <h4>
                      Hãy đăng ký qua 2 phương thức thanh toán bên dưới:
                      <br />
                      <br />
                      1. Vietcombank sở giao dịch Hà Nội
                      <br />
                      Tên tài khoản: Lê Mai Hương
                      <br />
                      Số tài khoản: 0011004046273
                      <br />
                      2. Momo: 0388178448
                      <br />
                      Chủ tài khoản: LE MAI HUONG
                    </h4>
                  </Column>
                </TwoColumn>
                <SubmitButton onClick={onSubmit}>ĐĂNG KÝ</SubmitButton>
              </form>
            </div>
            <SvgDotPattern1 />
          </FormContainer>
        </Content>
        <DialogMessage
          message={message}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </LoadingComponent>
    </Container>
  );
};
