import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading as HeadingTitle,
  Subheading,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";
import KickUpCourseImageSrc from "images/KickUpCourse.png";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col justify-center items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded rounded-b-none`,
]);

const Details = tw.div`p-6 rounded border-2 border-t-0 rounded-t-none border-dashed border-primary-100 flex-1 flex-col items-center text-center lg:block lg:text-left`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex-col items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const Title = tw.h5`mt-4 leading-snug font-bold text-lg`;
const Description = tw.p`mt-2 text-sm text-secondary-100`;
const Link = styled(PrimaryButtonBase).attrs({ as: "a" })`
  ${tw`inline-block mt-4 text-sm font-semibold bg-main-2`}
`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default ({
  subheading = "",
  heading = (
    <>
      Một số <span tw="text-main-2">khóa học</span> của chúng tôi
    </>
  ),
  description = "Các khóa học được nhiều học viên lựa chọn tại Mysheo.", //Some amazing blog posts that are written by even more amazing people.",
}) => {
  const blogPosts = [
    {
      imageSrc: "https://mysheo.com/cdn/shop/files/2_ec17be60-e46d-43f6-a007-83eb26313ab4_500x.png?v=1688542517",
      categories: ["CHỈ VỚI 500.000"],
      title: "KHÓA 1 TUẦN",
      description:
        "Bắt đầu mục tiêu lớn với chi phí nhỏ. Hãy trải nghiệm lớp học thử Phản Xạ của Hương Mysheo chỉ với chi phí 500.000 / tuần",
      url: "/phan-xa-tieng-anh",
    },
    {
      imageSrc:
        "https://mysheo.com/cdn/shop/files/3_c903bfdb-4904-423a-b20b-4c9d3e59902c_500x.png?v=1688394850", //"https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      categories: ["Chuyển kênh tư duy từ Việt sang Anh"],
      title: "TỰ TIN PHẢN XẠ",
      description:
        "Việc duy trì thói quen, thích nghi môi trường nói Tiếng Anh và học tập mỗi ngày sẽ là thành công lớn hơn cả việc cải thiện phản xạ Tiếng Anh.",
      url: "/tu-tin-phan-xa",
    },
    {
      imageSrc:
        "https://mysheo.com/cdn/shop/files/1_8fdcc773-333e-441d-949e-3cdaa109f2ee_500x.png?v=1688541862",
      categories: ["Chuyển Kênh Phản Xạ"],
      title: "BEST SELLER",
      description:
        "Chuyển kênh tư duy từ nền tảng, tăng tốc phản xạ Tiếng Anh mỗi ngày với 4 ca học.",
      url: "/chuyen-kenh-phan-xa",
    },
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <HeadingTitle>{heading}</HeadingTitle>
          <HeadingDescription>{description}</HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card>
                <Image
                  imageSrc={post.imageSrc}
                  style={{
                    height: "480px",
                    objectFit: "cover",
                  }}
                />
                <Details tw="flex justify-between">
                  <Title>{post.title}</Title>
                  <MetaContainer>
                    <Meta>
                      {/* <TagIcon /> */}
                      {post.categories?.map((category, index) => (
                        <div key={index} tw="text-sm p-1">{category}</div>
                      ))}
                    </Meta>
                  </MetaContainer>
                  <Description>{post.description}</Description>
                  {post.url && <Link href={post.url}>Tìm hiểu thêm</Link>}
                </Details>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
