import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header, {
  NavLinks,
  NavLink,
  PrimaryLink,
} from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import {
  Container,
  ContentWithVerticalPadding,
} from "components/misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as QuotesLeftIconBase } from "images/quotes-l.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/dot-pattern.svg";

// const Header = tw(HeaderBase)`max-w-none`;
const StyledHeader = styled(Header)`
  ${tw` max-w-none w-full`}
  ${NavLink} {
    ${tw` pl-5 mt-2 hover:border-gray-300`}
  }

`;

const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-6 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(
  Column
)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;
const Heading = tw(
  SectionHeading
)`text-left text-main-2 leading-snug lg:text-2xl xl:text-6xl`;
const Description = tw(
  SectionDescription
)`mt-4 lg:text-base text-gray-700 max-w-lg`;
const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 inline-block w-56 tracking-wide text-center py-5`;
const FeatureList = tw.ul`mt-12`;
const Feature = tw.li`flex items-center`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-main-1`;
const FeatureText = tw.p`ml-2 text-xl font-medium text-gray-700 basis-5/6`;
const ImageColumn = tw(Column)`ml-1 lg:mr-0 relative mt-16 lg:mt-0 lg:ml-32`;
const ImageContainer = tw.div`relative z-40 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image = tw.img`max-w-full w-96 rounded-t sm:rounded relative z-20`;

const Offsetbackground = tw.div`absolute inset-0 bg-gray-300 rounded xl:-mb-8`;
const ImageDecoratorBlob = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none z-10 absolute right-0 bottom-0 transform translate-x-10 translate-y-10 h-32 w-32 opacity-25 text-gray-900 fill-current`}
`;
const Testimonial = tw.div`max-w-sm rounded-b md:rounded-none relative sm:absolute bottom-0 inset-x-0 z-20 px-2 py-6 sm:px-10 sm:py-8 bg-main-2 text-gray-400 font-medium transform md:-translate-x-32 text-sm leading-relaxed md:-mr-16 xl:mr-0`;
const QuotesLeftIcon = tw(
  QuotesLeftIconBase
)`w-16 h-16 md:w-12 md:h-12 absolute top-0 left-0 text-gray-100 md:text-red-500 transform translate-x-1 md:-translate-x-1/2 md:-translate-y-5 opacity-10 md:opacity-100`;
const Quote = tw.blockquote``;
const CustomerName = tw.p`mt-4 font-bold`;
const CustomerCompany = tw.p`mt-1 text-sm text-gray-500`;

export default ({
  heading = "Hành trình ngàn dặm bắt đầu bằng một khóa học...", //"Better, Faster and Cheaper Cloud.",
  //description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  imageSrc = "https://drive.google.com/uc?export=view&id=1NvTxKkW6NXAyGIRVc7Tui2wO3gSByAds", //"https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
  imageDecoratorBlob = true,
  primaryButtonUrl = "https://google.com",
  primaryButtonText = "Get Started",
  buttonRounded = true,
  features = [
    "Tạo thói quen học tập hằng ngày.", //"Available in 7 Locations",
    "Lịch học online linh động từ 3 - 4 ca học 1 ngày.", //Premium Internet Backbone",
    "Môi trường học chìm đắm tiếng Anh.", //99.99% Uptime SLA",
  ],
  testimonial = {
    quote:
      "Hành trình 10 năm chinh phục ước mơ của một cô gái trẻ từ Hà Nội → Sài Gòn → San Fansisco. Từng trải nghiệm đi qua đều là một bài học quý giá, Hương vẫn không ngừng học hỏi và nỗ lực để ngày hôm nay có thể ở đây và trở thành người đồng hành cùng các bạn trên chặng đường sắp tới.", //"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    // customerName: "Hương Mysheo", //Charlotte Hale",
    //customerCompany: "Founder", //"Delos Inc.",
  },
  LP3 = false,
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;
  // const navLinks = [
  //   <NavLinks key={1}>
  //     {/*} <NavLink href="/#">About</NavLink>
  //     <NavLink href="/#">Blog</NavLink>
  //     <NavLink href="/#">Pricing</NavLink>
  //     <NavLink href="/#">Contact Us</NavLink>
  // <NavLink href="/#">Testimonials</NavLink>*/}
  //   </NavLinks>,
  // ];
  const navLinks = [
    <NavLink key={0} href="#introduce">
      Thông tin chi tiết
    </NavLink>,
    <NavLink key={1} href="#info">
      Giải đáp thắc mắc
    </NavLink>,
  ];
  return (
    <>
      {/* {!LP3 && <Header links={navLinks} />} */}
      <StyledHeader links={navLinks} />
      <Container>
        <ContentWithVerticalPadding>
          <Row>
            <TextColumn>
              <Heading>{heading}</Heading>
              {/*<Description>{description}</Description>
              <PrimaryButton as="a" href={primaryButtonUrl} css={buttonRoundedCss}>
                {primaryButtonText}
              </PrimaryButton> */}
              <FeatureList>
                {features.map((feature, index) => (
                  <Feature key={index}>
                    {index !== 4 && index !== 0  && <FeatureIcon />}
                    <FeatureText>{feature}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </TextColumn>
            <ImageColumn>
              <ImageContainer>
                <Image src={imageSrc} />
                {imageDecoratorBlob && <ImageDecoratorBlob />}
                <Testimonial>
                  <QuotesLeftIcon />
                  <Quote
                    style={{
                      width: "300px",
                      fontSize: "15px",
                      textAlign: "justify",
                      fontWeight: "900",
                    }}
                  >
                    {testimonial.quote}
                  </Quote>
                  <CustomerName>{testimonial.customerName}</CustomerName>
                  <CustomerCompany>
                    {testimonial.customerCompany}
                  </CustomerCompany>
                </Testimonial>
              </ImageContainer>
              <Offsetbackground />
            </ImageColumn>
          </Row>
        </ContentWithVerticalPadding>
      </Container>
    </>
  );
};
