import React, { useEffect, useState } from "react";
import { sheetApi } from "api/sheet-api";
import { DialogMessage } from "DialogMessage";
import styled from "styled-components";
import tw from "twin.macro";
import { LoadingComponent } from "LoadingComponent";

const Content = styled.div`
  ${tw`px-2 flex flex-col  justify-evenly`}
`;
const FormContainer = styled.div`
  ${tw`shrink z-20 relative lg:my-3 rounded-lg border-8 border-solid border-[#F8BD32] bg-white mx-4 px-8 pt-8`}
  background: #FFFEF5;
  background: linear-gradient(to bottom, #fffef5 0%, #ffffff 45%);
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-base sm:text-2xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full bg-transparent text-black text-lg md:text-xl font-medium tracking-wide border-b-2 py-2 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;
const Title = tw.h4`text-main-2 font-bold text-base text-center md:text-xl lg:text-2xl xl:text-4xl `;

const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold  text-base text-center md:text-2xl`;
const Column = tw.div` flex flex-col`;
const InputContainer = styled.div`
  ${tw` relative py-5 mt-3 px-0`}
  min-width: 250px;


  @media (min-width: 1120px) and (max-width: 1230px) {
    min-width: 350px;
  }

  @media (min-width: 1231px) and (max-width: 1279px) {
    min-width: 450px;
  }

  @media (min-width: 1280px)  {
    min-width: 350px;
  }

  `;

const Input = styled.input`
  ${tw` rounded-md outline-none text-6xl lg:text-8xl my-5`}
  ${({ required, value }) => required && !value && tw`border-red-500`}
`;
const SubmitButton = tw.button` my-3 py-3 px-4 self-center bg-[#F8BD32] text-main-2 rounded-full font-bold tracking-wide shadow-lg uppercase  text-lg  md:text-xl lg:text-2xl transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;
const Description = tw.p`absolute top-0 left-0 mb-3 tracking-wide font-semibold  text-base text-center md:text-xl`;
const CustomSelect = styled.select`
  ${tw`w-full bg-transparent text-black  text-base text-center md:text-xl font-medium tracking-wide border-b-2 pb-2 pt-6 focus:outline-none transition duration-200`}
  option {
    ${tw`bg-white text-black`}
  }
  ${({ required, value }) => required && !value && tw`border-red-500`}
`;

export default ({
  sheetName,
  hasDiscountCode = false,
  content,
  courseDefault,
  coursesType,
  countDown = false,
  startTime = undefined,
  LP = undefined,
  footer = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
    email: "",
    paymentStatus: "undefined",
    howDidYouHearAboutUs: "undefined",
    learningGoal: "",
    discountCode: "",
    course: courseDefault || "",
    selectDate: "undefined",
  });

  const successMessage =
    "Hương Mysheo sẽ kiểm tra lại thanh toán! Mail xác nhận thanh toán thành công sẽ có sau 24 tiếng, học viên check mail để login vào website học tập. Nếu trong 48 giờ, học viên chưa nhận được mail xác nhận, vui lòng gửi mail về Support@mysheo.com để được hỗ trợ!.";

  const onSubmit = async (e) => {
    e.preventDefault();
    const {
      name,
      phone,
      email,
      paymentStatus,
      howDidYouHearAboutUs,
      learningGoal,
      discountCode,
      course,
      selectDate,
    } = formValues;

    console.log("selectDate", formValues);

    if (!name || !phone || !email) {
      setMessage("Vui lòng kiểm tra lại và điền đầy đủ thông tin chi tiết");
      setIsOpen(true);
      return;
    }

    try {
      setLoading(true);
      const user = formValues;
      await sheetApi.registerCourse(
        { ...user, landingPage: sheetName },
        "Raw LP"
      );
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "new_subscriber",
        formLocation: "footer",
      });
      setLoading(false);
      setMessage(successMessage);
      setIsOpen(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setMessage("Đã có lỗi xảy ra, xin vui lòng thử lại sau!");
      setIsOpen(true);
    }
  };

  return (
    <LoadingComponent loading={loading}>
      <FormContainer>
        <form action="#">
          <Title>Đăng ký tư vấn</Title>
          <Column>
            <InputContainer>
              <Label htmlFor="name-input">Tên của bạn</Label>
              <Input
                id="name-input"
                type="text"
                name="name"
                placeholder="Họ tên"
                value={formValues.name}
                onChange={(e) =>
                  setFormValues({ ...formValues, name: e.target.value })
                }
                required
              />
            </InputContainer>
            <InputContainer>
              <Label htmlFor="phone-input">Số điện thoại</Label>
              <Input
                id="phone-input"
                type="tel"
                name="phone"
                placeholder="Số điện thoại"
                value={formValues.phone}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    phone: e.target.value,
                  })
                }
                required
              />
            </InputContainer>
            <InputContainer>
              <Label htmlFor="email-input">Email</Label>
              <Input
                id="email-input"
                type="email"
                name="email"
                placeholder="Email"
                value={formValues.email}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    email: e.target.value,
                  })
                }
                required
              />
            </InputContainer>
            <SubmitButton onClick={onSubmit}>ĐĂNG KÝ NGAY</SubmitButton>
          </Column>
        </form>
      </FormContainer>
      <DialogMessage message={message} isOpen={isOpen} setIsOpen={setIsOpen} />
    </LoadingComponent>
  );
};
