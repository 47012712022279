import React, { useEffect, useState, useRef } from "react";
import styled, { keyframes } from "styled-components";
import tw from "twin.macro";

import { ReactComponent as ArrowPath } from "images/arrow-path.svg";

const Container = tw.div`relative z-0 `;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Backdrop = styled.div`
  ${tw`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50`}
  font-size: ${({ size }) => size}px;
`;

const Spinner = styled(ArrowPath)`
  ${tw`text-white text-xl animate-spin`}
  animation: ${spinAnimation} 1s linear infinite;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

export const LoadingComponent = ({ loading, children, minSize = 100 }) => {
  const [parentSize, setParentSize] = useState(0);
  const parentRef = useRef(null);

  useEffect(() => {
    if (!parentRef || !parentRef.current) return;
    const { clientHeight, clientWidth } = parentRef.current;
    setParentSize(Math.max(minSize, Math.min(clientHeight, clientWidth)));
  }, [parentRef]);

  return (
    <Container ref={parentRef}>
      {children}
      {loading && (
        <Backdrop size={parentSize}>
          <Spinner size={5 * Math.sqrt(parentSize)} />
        </Backdrop>
      )}
    </Container>
  );
};
