import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import PreferentialForm from "components/forms/PreferentialForm";
import LogoImage from "../../images/logo.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";

const Container = tw.div`relative bg-white -mx-8 -mb-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto  flex flex-wrap justify-center items-center`;
const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Row = tw.div`flex items-center justify-center flex-col px-8`;

const Column = tw.div`lg:w-2/5 flex flex-col items-center lg:items-start`;
const WideColumn = tw(
  Column
)`text-center md:text-left w-full lg:w-3/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const Title = tw.h4`text-black font-bold  text-base text-center md:text-xl lg:text-2xl xl:text-4xl `;
const Title2 = tw.h4`text-main-2 whitespace-nowrap font-black text-lg text-left md:text-xl lg:text-2xl `;
const Title3 = tw.h4`text-gray-900 lg:px-5 text-base text-left md:text-lg `;

const LogoImg = tw.img`w-8 scale-150`;
const LogoText = tw.h5`ml-2 text-lg md:text-xl font-bold text-main-2`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`flex items-center`;
const SocialLink = styled.a`
  ${tw`cursor-pointer  inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-8 h-8`}
  }
  i {
    font-size: 1.75rem;
    padding-left: 0.25rem;
    line-height: 1;
    ${tw`w-8 h-8`};
  }
`;
const SocialLink2 = styled.div`
  ${tw`m-2`}
  .action {
    ${tw`text-center rounded-full inline-block w-full p-3 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline`}
  }
  .bg-main-0 {
    ${tw`bg-main-0 `}
  }
  .bg-main-1 {
    ${tw`bg-main-1 `}
  }
  .bg-main-2 {
    ${tw`bg-main-2 `}
  }
  .bg-main-3 {
    ${tw`bg-main-3 `}
  }
  .bg-main-4 {
    ${tw`bg-main-4 `}
  }
  .bg-black {
    ${tw`bg-black `}
  }
  i {
    font-size: 1.5rem;
    line-height: 1;
    ${tw`text-white`};
  }
`;

const CopyrightText = tw.p`text-center my-3 font-medium tracking-wide text-sm text-black`;

export default () => {
  return (
    <Container tw="-mt-12 mb-10">
      <FiveColumns>
        <WideColumn>
          <PreferentialForm
            sheetName="LP3"
            LP="LP3"
            courseDefault="CKPX4-3m"
            hasDiscountCode={true}
            footer={true}
          />
        </WideColumn>
        <Column>
          <Title2>THÔNG TIN LIÊN HỆ</Title2>
          <LinksContainer>
            <Link href="#">
              <SocialLinksContainer>
                <SocialLink2>
                  <a href="#" class="action bg-black">
                    <i class="fa-solid fa-location-dot"></i>
                  </a>
                </SocialLink2>
                <Title3>
                  121, Bạch Đằng, Phường 2, Tân Bình, Thành phố Hồ Chí Minh
                </Title3>
              </SocialLinksContainer>
              <SocialLinksContainer>
                <SocialLink2>
                  <a href="#" class="action bg-black">
                    <i class="fa-solid fa-envelope"></i>
                  </a>
                </SocialLink2>
                <Title3>support@mysheo.com</Title3>
              </SocialLinksContainer>
              <SocialLinksContainer>
                <SocialLink2>
                  <a href="#" class="action bg-black">
                    <i class="fa-solid fa-phone-volume"></i>
                  </a>
                </SocialLink2>
                <Title3> 0768074203</Title3>
              </SocialLinksContainer>
            </Link>
          </LinksContainer>

          <Title2 tw="my-4">THEO DÕI</Title2>
          <LogoContainer>
            <LogoImg src="https://drive.google.com/uc?export=view&id=1hKdjd0il_NayqRrSy8dA4FtyFkqtk8Ko" />
            <LogoText>Hương Mysheo</LogoText>
          </LogoContainer>
          <SocialLinksContainer tw="gap-6 my-4">
            <SocialLink href="https://www.facebook.com/huongmysheo">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://www.tiktok.com/@mysheoenglish">
              <i class="fa-brands fa-tiktok"></i>
            </SocialLink>
            <SocialLink href="https://www.youtube.com/@huongmysheo288">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>

          <CopyrightText>
            <Link tw="mr-6" href="https://drive.google.com/file/d/1y68VcVIoeJZ8xy46irgQ16HSVZkv-RyG/view">
              Terms and Policies
            </Link>
            &copy; Hương Mysheo
          </CopyrightText>
        </Column>
      </FiveColumns>
    </Container>
  );
};
