import React from "react";
import Countdown from "react-countdown";
import tw, { styled } from "twin.macro";

const Container = styled.div`
  ${tw`flex justify-center items-center mt-4 rounded`}
`;
const CountdownWrapper = styled.div`
  ${tw`text-xl tracking-tighter md:text-2xl lg:text-5xl flex flex-wrap items-center md:space-x-2 font-bold text-center rounded`}
`;
const BoxWrapper = styled.div`
  ${tw`flex-col rounded border-4 border-solid border-main-2 bg-white`}
`;
const Box = styled.div`
  ${tw`flex-col rounded bg-white text-main-2 leading-tight`}
`;
const Title = tw.h6`text-sm pl-1 tracking-tighter md:text-2xl lg:text-4xl text-main-2  font-bold`;
const Title2 = styled(Title)`
  ${tw`text-xs md:text-2xl lg:text-5xl tracking-tighter`}
`;
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  return (
    <CountdownWrapper>
      <BoxWrapper>
        <Box>{days}</Box>
        <Title>ngày&nbsp;</Title>
      </BoxWrapper>
      <BoxWrapper>
        <Box>{hours}</Box>
        <Title>giờ&nbsp;</Title>
      </BoxWrapper>
      <BoxWrapper>
        <Box>{minutes}</Box>
        <Title>phút&nbsp;</Title>
      </BoxWrapper>
      <BoxWrapper>
        <Box>{seconds}</Box>
        <Title>giây&nbsp;</Title>
      </BoxWrapper>
    </CountdownWrapper>
  );
};

/*deadlineString="YYYY-MM-DDTHH:MM:SS+07:00" (UTC + 7)*/
export const CountDown = ({ deadlineString }) => {
  const deadline = new Date(deadlineString);
  return (
    <Container>
      <Countdown date={deadline} renderer={renderer} />
    </Container>
  );
};
