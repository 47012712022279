import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import ResponsiveVideoEmbed from "helpers/ResponsiveVideoEmbed.js";

import Header, {
  LogoLink,
  NavLinks,
  NavLink as NavLinkBase,
} from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`justify-between`}
  ${LogoLink} {
    ${tw`mr-8 pb-0`}
  }
`;

const NavLink = tw(NavLinkBase)`
  sm:text-sm sm:mx-6
  whitespace-nowrap
`;

const Container = tw.div`relative -mx-8 h-full`;
const TwoColumn = tw.div`flex flex-col lg:flex-row bg-gray-100 h-full`;

const LeftColumn = styled.div`
  ${tw`ml-8 mr-8`}
  flex-basis: 40%;
`;
const RightColumn = styled.div`
  ${tw`ml-8 mr-8 bg-gray-100 bg-cover bg-center xl:ml-24 mb-10 lg:max-h-screen lg:w-1/2 lg:flex-auto`}
  max-height: 150vh;
  flex-basis: 60%;
`;

// const url = "https://www.youtube.com/embed/RA9gJQpN6PY";
const Content = tw.div`mt-16 lg:mt-16 lg:mb-2 flex flex-col sm:items-center lg:items-stretch`;
const Heading = tw.h1`text-3xl sm:text-5xl md:text-6xl lg:text-5xl font-black leading-tight`;
const Paragraph = tw.p` my-5 lg:my-3 lg:my-5 sm:text-lg lg:text-base xl:text-lg leading-loose`;
const Title1 = tw.h5` text-lg text-main-2 sm:text-xl md:text-2xl lg:text-xl font-black`;
const Title2 = tw.p`max-w-lg sm:text-lg font-bold lg:text-base xl:text-lg leading-loose`;

const Column = tw.div`flex justify-end items-center`;
const ImageColumn = tw(Column)`ml-1 lg:mr-0 relative mt-16 lg:mt-0 lg:ml-32`;
const ImageContainer = tw.div`relative z-40 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image = tw.img`flex justify-end my-16 rounded-t sm:rounded relative z-20`;

const StyleImage = styled(Image)`
  height: 850px;
`;

const Actions = styled.div`
  ${tw`my-8 lg:mb-0 animate-bounce`}
  .action {
    ${tw`text-center rounded-full inline-block w-full sm:w-48 py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  }
  .primaryAction {
    ${tw`bg-main-3 text-gray-100 hover:bg-primary-700 text-2xl`}
  }
  .secondaryAction {
    ${tw`mt-4 sm:mt-0 sm:ml-4 bg-gray-300 text-gray-700 hover:bg-gray-400 hover:text-gray-800`}
  }
`;

const P = tw.p`text-main-2 mb-4 text-xs sm:text-5xl`;

const left = [<Title1></Title1>];

export default ({
  navLinks = [
    <NavLink key={0} href="#pricing">
      Tìm hiểu về khóa học
    </NavLink>,
    <NavLink key={1} href="#info">
      Giải đáp thắc mắc
    </NavLink>,
  ],
  heading = (
    <>
      TỰ TIN PHẢN XẠ
      <wbr />
      <br />
      <span tw="text-main-2 text-xs sm:text-4xl lg:text-2xl">
        Khởi động từ CHUYỂN KÊNH PHẢN XẠ
      </span>{" "}
      <br />
      <span tw="text-main-1 text-xs sm:text-4xl lg:text-2xl ">
      Học tập mỗi ngày từ 1 tháng - 4 tháng 
      </span>
    </>
  ),
  description = "Việc duy trì thói quen, thích nghi môi trường nói Tiếng Anh và học tập mỗi ngày sẽ là thành công lớn hơn cả việc cải thiện phản xạ Tiếng Anh.",
  schedules = [
    <Title1>HỌC TẬP ĐỦ LƯỢNG ĐỂ THAY ĐỔI VỀ CHẤT</Title1>,
    " Ngày khai giảng Thứ 2 - 09/10/2023  ",
  ],
  primaryActionUrl = "#registrationForm",
  primaryActionText = "Đăng ký ngay",
  showImage = false,
  url = "https://www.youtube.com/embed/RA9gJQpN6PY",
  // secondaryActionUrl = "#",
  // secondaryActionText = "Search Course"
}) => {
  console.log(url);
  return (
    <Container id="introduce">
      <TwoColumn>
        <LeftColumn>
          {!showImage && (
            <StyledHeader links={navLinks} collapseBreakpointClass="sm" />
          )}
          <Content>
            <Heading>{heading}</Heading>
            <Paragraph>{description}</Paragraph>
            {schedules.map((item, index) => (
              <Title2 key={index}>{item}</Title2>
            ))}
            {/* {deadlinetimes.map((time, index) => (
              <Title2 key={index}>{time}</Title2>
            ))} */}
            <Actions>
              <a href={primaryActionUrl} className="action primaryAction">
                {primaryActionText}
              </a>
            </Actions>
          </Content>
        </LeftColumn>
        <RightColumn>
          {!showImage && <ResponsiveVideoEmbed url={url} />}
          {showImage && (
            <Content>
              <Heading>
                <>
                  Phương pháp học tập
                  <wbr />
                  <br />
                </>
              </Heading>
              <Paragraph>
                <>
                  - Học tập chia nhỏ (Micro-learning): tăng cường khả năng ghi
                  nhớ và tập trung tối đa.
                  <br />
                  - Học qua hình ảnh (Visual Learning) kích thích trí tưởng
                  tượng, ghi nhớ từ vựng dễ dàng, hình thành khả năng phản xạ
                  khi giao tiếp.
                  <br />
                  - Học qua âm thanh (Auditory Learning) cải thiện khả
                  năng nghe, hoàn thiện phát âm và giao tiếp.
                </>
                <Heading tw="pt-10">Các hoạt động học tập</Heading>
                <wbr /> <br/>
                {[
                  <Title1> Trước buổi học:</Title1>,
                  "Xem trước từ vựng và ngữ pháp thông qua các bài tập trắc nghiệm (Quiz), thẻ từ vựng (Flashcard) trên website ",
                ]}
                {[
                  <Title1> Trong buổi học:</Title1>,
                  "Thực hành luyện nói tiếng Anh với 24 chủ đề thực tế nhất. ",
                  "Luyện phản xạ nhanh thông qua hình ảnh và bài kiểm tra nghe. ",
                  "Bắt cặp thảo luận với bạn học theo hướng dẫn (Peer to Peer).",
                ]}
                {[
                  <Title1> Sau buổi học:</Title1>,
                  "Ôn tập sau buổi học bằng bài tập viết được chấm chữa 1-1 ",
                ]}
              </Paragraph>
              {left.map((item, index) => (
                <Title2 key={index}>{item}</Title2>
              ))}
              {/* {deadlinetimes.map((time, index) => (
              <Title2 key={index}>{time}</Title2>
            ))} */}
            </Content>
          )}
          {/* <ResponsiveVideoEmbed url={url}> </ResponsiveVideoEmbed> */}
          {/* <Title1 tw="text-main-2 mt-6">
          Top 3 lợi ích của mô hình học Phản Xạ. &nbsp;
          </Title1>
          <Title1 tw="text-main-1">Học viên được học nhiều hơn 1 ca học / ngày.</Title1>
          <Title1 tw="text-main-2">
          Học viên được học mọi lúc, không chỉ trong thời gian 30 phút trực tiếp. &nbsp;
          </Title1>
          <Title1 tw="text-main-2">
          Học viên được linh động tham gia các khung học khác nhau. 
          </Title1>
          <p>(Áp dụng khi đăng ký từ 31/5 - 2/6)</p> */}
        </RightColumn>
      </TwoColumn>
    </Container>
  );
};
