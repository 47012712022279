import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import backgroundImage from "../../images/Background.png";
import MysheoPic from "../../images/MysheoSmile.png";
import { CountDown } from "../../CountDown";
import { sheetApi } from "api/sheet-api";
import { DialogMessage } from "DialogMessage";
import { LoadingComponent } from "LoadingComponent";
import PreferentialForm from "components/forms/PreferentialForm";
import { ReactComponent as ChatIcon } from "images/chat-bubble.svg";
import { ReactComponent as Square3DIcon } from "images/square-3-stack-3d.svg";
import { ReactComponent as DesptopIcon } from "images/computer-desktop.svg";

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink as PrimaryLinkBase,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`w-full bg-white pl-3`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-black pl-5 mt-2 hover:border-gray-300 hover:text-gray-900`}
  }
  ${NavToggle}.closed {
    ${tw`text-black hover:text-primary-900`}
  }
`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`;
const Container = styled.div`
  ${tw`relative flex-col lg:flex-row -mx-8  bg-center bg-main-2 bg-cover `}
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 opacity-75`;
const ImageOverlay = styled.div`
  ${tw`z-10 hidden lg:flex relative justify-start items-stretch self-end order-last lg:order-first -mb-2 lg:mb-0`}
`;

const HeroContainer = tw.div`z-20 flex flex-col items-stretch justify-end lg:flex-row`;
const Content = styled.div`
  ${tw`px-2 mr-6 lg:mr-16 w-full flex flex-col justify-evenly items-stretch lg:items-end gap-4`}
`;

const Heading = styled.h3`
  ${tw` text-lg text-center whitespace-nowrap md:text-3xl lg:text-5xl xl:text-7xl font-black`}
  span {
    ${tw`inline-block`}
  }
  background: #f8bd32;
  background: linear-gradient(to right, #f8bd32 75%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
const SubHeading = styled.h1`
  ${tw`text-lg text-center sm:text-xl lg:text-2xl xl:text-4xl font-black text-gray-100 leading-snug`}
  span {
    ${tw`inline-block`}
  }
`;
const Title = tw.h4`text-white font-bold text-base text-center md:text-xl lg:text-2xl xl:text-4xl `;
const Title2 = tw.h4`text-main-2 lg:px-5 font-bold text-base text-left md:text-2xl lg:text-3xl xl:text-4xl `;
const Paragraph = tw.p` text-xl font-bold leading-loose`;

const Image = tw.img`max-w-full rounded-t sm:rounded xl:pr-0 scale-0 md:scale-100  order-last lg:order-first lg:basis-4/5`;

const WrapBox = tw.div`rounded-lg border-4 border-solid px-6 border-[#f8bd32] drop-shadow-2xl shadow-raised flex flex-col items-center py-1 m-2 sm:py-0`;

const Box = styled.div`
  ${tw`rounded-lg flex flex-row items-center bg-white border-4 border-solid border-[#f8bd32]`}
`;

const SocialLink = styled.a`
  ${tw`px-3`}
  svg {
    ${tw`w-8 h-8 lg:w-16 lg:h-16`}
  }
`;

export default ({
  contents = [
    {
      title: (
        <>
          {" "}
          Người đi làm&nbsp;<span tw="font-black">bận rộn,</span>
          &nbsp;cần lộ trình&nbsp;
          <sanp tw="font-black">học online linh hoạt.</sanp>&nbsp;
        </>
      ),
      icon: Square3DIcon,
    },
    {
      title: (
        <>
          Người cần nâng cao trình độ giao tiếp tiếng Anh để&nbsp;
          <span tw="font-black">phục vụ cho công việc.</span>
        </>
      ),
      icon: DesptopIcon,
    },
    {
      title: (
        <>
          Người&nbsp;<span tw="font-black">muốn tăng phản xạ</span>
          &nbsp;hoặc đã có nền tảng tiếng Anh cơ bản nhưng&nbsp;
          <sanp tw="font-black">chưa giao tiếp được.</sanp>&nbsp;
        </>
      ),
      icon: ChatIcon,
    },
  ],
}) => {
  return (
    <Container id="who">
      <HeroContainer>
        <ImageOverlay>
          <Image src={MysheoPic} />
        </ImageOverlay>
        <Content tw="z-20 lg:ml-0 ">
          <Heading style={{ marginTop: "0px" }}>
            Khóa học này dành cho ai
          </Heading>

          {contents?.length > 0 &&
            contents.map((content, index) => (
              <Box tw="mb-4">
                <SocialLink>
                  <content.icon />
                </SocialLink>
                <Title2>{content.title}</Title2>
              </Box>
            ))}
        </Content>
      </HeroContainer>
    </Container>
  );
};
