import { getConfig } from "../config";

const registerCourse = async (user, sheetName) => {
  const response = await fetch(
    `${getConfig().backend}/flex/sheet/register-course`,
    {
      method: "POST",
      body: JSON.stringify({ user, sheetName }),
    }
  );

  if (!response.ok) {
    throw new Error(
      `Sheet register course response ${response.status} ${
        response.statusText
      }: ${await response.text()}`
    );
  }
};

export const sheetApi = {
  registerCourse,
};
