import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import backgroundImage from "../../images/Background.png";
import MysheoPic from "../../images/mysheo.png";
import { CountDown } from "../../CountDown";
import { sheetApi } from "api/sheet-api";
import { DialogMessage } from "DialogMessage";
import { LoadingComponent } from "LoadingComponent";
import PreferentialForm from "components/forms/PreferentialForm";

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink as PrimaryLinkBase,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`w-full bg-white pl-3`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-black pl-5 mt-2 hover:border-gray-300 hover:text-gray-900`}
  }
  ${NavToggle}.closed {
    ${tw`text-black hover:text-primary-900`}
  }
`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`;
const Container = styled.div`
  ${tw`relative flex-col lg:flex-row -mx-8 -mt-8 bg-center bg-main-2 bg-cover `}
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 opacity-75`;
const ImageOverlay = styled.div`
  ${tw`z-10 relative justify-start items-stretch self-end order-last lg:order-first -mb-2 lg:mb-0`}
`;

const HeroContainer = tw.div`z-20 flex flex-col items-stretch justify-end lg:flex-row`;
const Content = styled.div`
  ${tw`px-2 flex flex-col  justify-evenly`}
`;

const Heading = styled.h1`
  ${tw` text-3xl text-left whitespace-nowrap md:text-4xl xl:text-5xl font-black`}
  span {
    ${tw`inline-block`}
  }
  background: #f8bd32;
  background: linear-gradient(to right, #f8bd32 75%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
const SubHeading = styled.h3`
  ${tw`text-lg text-left sm:text-xl xl:text-3xl font-black text-gray-100 leading-snug `}
  span {
    ${tw`inline-block`}
  }
`;
const Title = tw.h4`text-white font-bold text-base text-center md:text-xl  xl:text-3xl `;
const Title2 = tw.h4`text-main-2 lg:px-2 whitespace-nowrap font-bold text-base text-center md:text-2xl xl:text-3xl `;
const Paragraph = tw.p` text-xl font-bold leading-loose whitespace-nowrap`;

const HeadingContainer = tw.div`flex items-center justify-start	gap-2`;
const Image = tw.img`max-w-full rounded-t sm:rounded xl:pr-0 hidden	 md:block md:scale-100 lg:scale-125 xl:scale-100 transform origin-bottom  order-last lg:order-first lg:basis-4/5`;

const WrapBox = tw.div`rounded-lg border-8 border-solid border-white drop-shadow-2xl shadow-raised flex flex-col items-center py-1 m-2 sm:py-0`;

const Box = styled.div`
  ${tw`rounded-lg flex flex-col items-center bg-white`}
  background: #FFFDBA;
  background: linear-gradient(to bottom, #fffdba 0%, #ffffff 100%);
`;

export default ({
  sheetName,
  hasDiscountCode = false,
  content,
  courseDefault,
  coursesType,
  countDown = false,
  startTime = undefined,
  LP = undefined,
  mainTitle = (
    <>
      {" "}
      <p tw="text-left whitespace-nowrap">Chuyển kênh phản xạ</p>
      <p tw="text-left whitespace-nowrap">Học online trực tiếp</p>
    </>
  ),
  subTitle1 = "Học 4 ngày/tuần trong 3 tháng",
  subTitle2 = "Chỉ 4.500.000 cho full lộ trình",
  wrapContent = [
    {
      title: "Đều đặn duy trì thói quen",
      highlined: "",
    },
    {
      title: "học Tiếng Anh",
      highlined: "MỖI NGÀY",
    },
  ],
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
    email: "",
    paymentStatus: "undefined",
    howDidYouHearAboutUs: "undefined",
    learningGoal: "",
    discountCode: "",
    course: courseDefault || "",
    selectDate: "undefined",
  });
  const navLinks = [
    <div tw="grow" key={0}></div>,
    <NavLink key={1} href="#who">
      <Paragraph>Đối tượng tham gia</Paragraph>
    </NavLink>,
    <NavLink key={2} href="#when" tw="mr-16">
      <Paragraph>Thông tin khóa học</Paragraph>
    </NavLink>,
  ];

  const successMessage =
    "Hương Mysheo sẽ kiểm tra lại thanh toán! Mail xác nhận thanh toán thành công sẽ có sau 24 tiếng, học viên check mail để login vào website học tập. Nếu trong 48 giờ, học viên chưa nhận được mail xác nhận, vui lòng gửi mail về Support@mysheo.com để được hỗ trợ!.";

  const onSubmit = async (e) => {
    e.preventDefault();
    const {
      name,
      phone,
      email,
      paymentStatus,
      howDidYouHearAboutUs,
      learningGoal,
      discountCode,
      course,
      selectDate,
    } = formValues;

    console.log("selectDate", formValues);

    if (!name || !phone || !email || !learningGoal) {
      setMessage("Vui lòng kiểm tra lại và điền đầy đủ thông tin chi tiết");
      setIsOpen(true);
      return;
    }

    try {
      setLoading(true);
      const user = formValues;
      await sheetApi.registerCourse(
        { ...user, landingPage: sheetName },
        "Raw LP"
      );
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "new_subscriber",
        formLocation: "footer",
      });
      setLoading(false);
      setMessage(successMessage);
      setIsOpen(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setMessage("Đã có lỗi xảy ra, xin vui lòng thử lại sau!");
      setIsOpen(true);
    }
  };

  return (
    <Container>
      <StyledHeader links={navLinks} />{" "}
      <HeroContainer>
        <Content tw="flex flex-row">
          <ImageOverlay>
            <Image src={MysheoPic} />
          </ImageOverlay>
          <Content tw="z-20 lg:-ml-12 basis-3/4 lg:justify-center lg:gap-20">
            <div tw="flex flex-col justify-start">
              <HeadingContainer tw="md:-mb-3">
                <Heading tw="ml-3 md:ml-0">{mainTitle}</Heading>
              </HeadingContainer>
              <SubHeading tw="text-white self-start whitespace-nowrap lg:mt-1">
                {subTitle1}
              </SubHeading>
            </div>
            <Box>
              <Title2>{subTitle2}</Title2>
            </Box>

            <WrapBox>
              {wrapContent?.length > 0 &&
                wrapContent.map((line, index) => (
                  <Title key={index} tw="not-italic whitespace-nowrap">
                    {line.title}{" "}
                    {line.highlined && (
                      <Heading tw="inline  text-lg md:text-2xl xl:text-4xl">
                        {line.highlined}
                      </Heading>
                    )}
                  </Title>
                ))}
            </WrapBox>
          </Content>
        </Content>
        <Content tw="items-center z-20">
          <PreferentialForm
            sheetName="LP3"
            LP="LP3"
            courseDefault="CKPX4-3m"
            hasDiscountCode={true}
          />
        </Content>
      </HeroContainer>
    </Container>
  );
};
