import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";
import ResponsiveVideoEmbed from "helpers/ResponsiveVideoEmbed.js";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 lg:items-stretch`;

const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-end`;
const Column = tw.div``;
const url = "https://www.youtube.com/embed/RA9gJQpN6PY";

// const Image = styled.div(props => [
//   `background-image: url("${props.imageSrc}");`,
//   props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
//   props.imageShadow ? tw`shadow` : tw`shadow-none`,
//   tw`hidden lg:block rounded h-144 bg-center`
// ]);

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-left`;
const Heading = tw(SectionHeading)`text-3xl lg:text-left`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const FAQSContainer = tw.dl`mt-12`;
const FAQ = tw.div` mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`; //cursor-pointe
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-primary-500 text-gray-100 p-1 rounded-full group-hover:bg-primary-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion(
  tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`
);

export default ({
  subheading = "",
  headings = ["Chia sẻ từ học viên"],
  descriptions = [
    "Dưới đây là những điểm mạnh của khóa học mà học viên yêu thích nhất.",
  ],
  // imageSrc = "https://images.unsplash.com/photo-1579427421635-a0015b804b2e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
  // imageContain = false,
  // imageShadow = true,
  faqs = null,
  showFaqs = true,
}) => {
  /*
   * You can modify FAQs either by modifying the below defaultFaqs array or by passing a custom array of FAQs using
   * the faqs prop
   */
  const defaultFaqs = [
    {
      question: "Thời gian học linh động",
      answers: [],
    },
    {
      question: "Được học nhiều hơn 1 ca học",
      answers: [],
    },
    {
      question: "Duy trì việc học mỗi ngày ",
      answers: [],
    },
    {
      question: "Có nhiều thời gian luyện phản xạ",
      answers: [],
    },
  ];

  if (!faqs || faqs.length === 0) faqs = defaultFaqs;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <Content>
        <TwoColumn>
          <Column tw="grow order-last lg:w-full lg:mx-24">
            <ResponsiveVideoEmbed tw="lg:mb-6" url={url}>
              {" "}
            </ResponsiveVideoEmbed>
          </Column>
          <Column tw="mb-10">
            <FAQContent>
              {subheading ? <Subheading>{subheading}</Subheading> : null}
              {headings?.length > 0 &&
                descriptions?.length > 0 &&
                headings.map((heading, index) => {
                  return (
                    <>
                      <Heading>{heading}</Heading>
                      <Description>{descriptions[index]}</Description>
                    </>
                  );
                })}
              {showFaqs && (
                <FAQSContainer>
                  {faqs.map((faq, index) => (
                    <FAQ
                      key={index}
                      // onClick={() => {
                      //   toggleQuestion(index);
                      // }}
                      className="group"
                    >
                      <Question>
                        <QuestionText>{faq.question}</QuestionText>
                        {/* <QuestionToggleIcon tw="bg-main-2">
                      {activeQuestionIndex === index ? (
                        <MinusIcon tw="bg-main-2"/>
                      ) : (
                        <PlusIcon tw="bg-main-2"/>
                      )}
                    </QuestionToggleIcon> */}
                      </Question>
                      {/* {faq.answers.map((answer) => (
                    <Answer
                      variants={{
                        open: {
                          opacity: 1,
                          height: "auto",
                          marginTop: "16px",
                        },
                        collapsed: {
                          opacity: 0,
                          height: 0,
                          marginTop: "0px",
                        },
                      }}
                      initial="collapsed"
                      animate={
                        activeQuestionIndex === index ? "open" : "collapsed"
                      }
                      transition={{
                        duration: 0.3,
                        ease: [0.04, 0.62, 0.23, 0.98],
                      }}
                    >
                      {answer}
                    </Answer>
                  ))} */}
                    </FAQ>
                  ))}
                </FAQSContainer>
              )}
            </FAQContent>
          </Column>
        </TwoColumn>
      </Content>
    </Container>
  );
};
