import { useState, useEffect, useRef } from "react";
import tw, { styled } from "twin.macro";

const Overlay = tw.div`fixed top-0 left-0 w-screen h-screen bg-black opacity-50`;
const DialogContainer = styled.div`
  ${tw`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 rounded shadow-lg`}
  position: fixed;
  z-index: 100;
`;
const DialogTitle = tw.h2`text-lg font-bold mb-4`;
const DialogContent = tw.p`mb-4`;
const DialogActions = tw.div`flex justify-end`;
const Button = tw.button`px-4 py-2 bg-main-2 text-white font-bold rounded`;

export const DialogMessage = ({ message, isOpen, setIsOpen }) => {
  const dialogRef = useRef(null);

  const closeDialog = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dialogRef.current && !dialogRef.current.contains(event.target)) {
      closeDialog();
    }
  };

  useEffect(() => {
    // Add event listener to disable scrolling on the body when the dialog is open
    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.body.style.overflow = "auto";
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div>
      {isOpen && (
        <>
          <DialogContainer ref={dialogRef}>
            <DialogTitle>Mysheo Team thông báo</DialogTitle>
            <DialogContent>{message}</DialogContent>
            <DialogActions>
              <Button onClick={closeDialog}>Close</Button>
            </DialogActions>
          </DialogContainer>
        </>
      )}
    </div>
  );
};
