import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(
  Column
)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-8 lg:mr-16 md:order-first`
    : tw`md:ml-8 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8`;

const Heading = tw(
  SectionHeading
)`text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-4`;
const Description1 = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-bold leading-relaxed text-secondary-100 mt-4`;
const Title3 = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-bold leading-relaxed text-secondary-100 mt-4`;

const Statistics = tw.div`mt-6 lg:mt-8 xl:mt-16 flex flex-wrap`;
const Statistic = tw.div`text-lg sm:text-2xl lg:text-3xl w-1/2 mt-4 lg:mt-10 text-center md:text-left`;
const Value = tw.div`font-bold text-primary-500`;
const Key = tw.div`font-medium text-gray-700`;

export default ({ textOnLeft = false }) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  const statistics = [
    {
      key: "6:15 - 6:45 Sáng",
      value: "Lớp 1",
    },
    {
      key: "8:15 - 8:45 Tối",
      value: "Lớp 2",
    },
    // {
    //   key: "Rooms",
    //   value: "2093",
    // },
    // {
    //   key: "Workers",
    //   value: "10347",
    // }
  ];

  return (
    <Container id="info">
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc="https://imgs.vietnamnet.vn/Images/2016/06/23/11/20160623114532-anh2.jpg" />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>Thông tin lớp học</Heading>
            <Description1> Ai có thể tham gia lớp học? </Description1>
            <Description>
            Bạn mất gốc, mất căn bản về tiếng Anh.             
              <br />
              Bạn chưa biết cách học, cảm thấy khó khăn khi bắt đầu học tiếng Anh.
              <br />
              Bạn thiếu từ vựng, gặp khó khăn khi phát âm Tiếng Anh từ những từ cơ bản.
            </Description>
            <Title3>Hãy tham gia học miễn phí với chủ đề Travelling, biết Tiếng Anh đi muôn nơi.</Title3>
            <Title3>
            Ngày học: Thứ 7 - 20/5/2023
              </Title3>
            <Statistics>
              {statistics.map((statistic, index) => (
                <Statistic key={index}>
                  <Value>{statistic.value}</Value>
                  <Key>{statistic.key}</Key>
                </Statistic>
              ))}
            </Statistics>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
