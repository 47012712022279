import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import ResponsiveVideoEmbed from "helpers/ResponsiveVideoEmbed.js";

import Header, {
  LogoLink,
  NavLinks,
  NavLink as NavLinkBase,
} from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`justify-between`}
  ${LogoLink} {
    ${tw`mr-8 pb-0`}
  }
`;

const NavLink = tw(NavLinkBase)`
  sm:text-sm sm:mx-6
  whitespace-nowrap
`;

const Container = tw.div`relative -mx-8 -mt-8 h-full`;
const TwoColumn = tw.div`flex flex-col lg:flex-row bg-gray-100 h-full`;

const LeftColumn = styled.div`
  ${tw`ml-8 mr-8 xl:pl-10 py-8`}
  flex-basis: 40%;
`;
const RightColumn = styled.div`
  ${tw` bg-gray-100 bg-cover bg-center xl:ml-24 mb-10 lg:max-h-screen lg:w-1/2 lg:flex-auto`}
  max-height: 150vh;
  flex-basis: 60%;
`;

const url = "https://www.youtube.com/embed/0SryUPbVjic";
const Content = tw.div`mt-16 lg:mt-16 lg:mb-2 flex flex-col sm:items-center lg:items-stretch`;
const Heading = tw.h1`text-base sm:text-4xl md:text-4xl lg:text-4xl font-black `;
const Paragraph = tw.p` self-start my-5 lg:my-3 lg:my-5 sm:text-lg lg:text-base xl:text-lg leading-loose`;
const Title1 = tw.h5` text-lg sm:text-xl md:text-2xl lg:text-xl font-black`;
const Title2 = tw.p`max-w-lg self-start sm:text-lg font-bold lg:text-base xl:text-lg leading-loose`;

const Actions = styled.div`
  ${tw`my-8 lg:mb-0 animate-bounce self-start`}
  .action {
    ${tw`text-center rounded-full inline-block w-full sm:w-48 py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  }
  .primaryAction {
    ${tw`bg-main-3 text-gray-100 hover:bg-primary-700 text-2xl`}
  }
  .secondaryAction {
    ${tw`mt-4 sm:mt-0 sm:ml-4 bg-gray-300 text-gray-700 hover:bg-gray-400 hover:text-gray-800`}
  }
`;

export default ({
  navLinks = [
    <NavLink key={0} href="https://mysheo.com/pages/about-me">
      Mysheo là ai?
    </NavLink>,
    <NavLink key={1} href="#info">
      Giải đáp về khóa học
    </NavLink>,
  ],
  heading = (
    <>
      TỰ TIN PHẢN XẠ 
      <wbr />
      <br />
      <span tw="text-main-2 leading-loose">Luyện nói liên tục trong 7 ngày</span>{" "}
      <br />
      <span tw="text-main-1"> CHỈ VỚI 500.000 </span>
    </>
  ),
  description = "Việc duy trì thói quen liên tục 7 ngày, thích nghi môi trường nói Tiếng Anh sẽ là thành công lớn hơn cả việc cải thiện phản xạ, tự tin giao tiếp Tiếng Anh. ",
  schedules = [
    "Học viên được chọn level (cơ bản hoặc trung cấp) với lớp phản xạ 1 tuần khai giảng vào thứ 2 tuần sau. Hình thức lớp online trực tiếp. ",
  ],
  deadlinetimes = [""],
  primaryActionUrl = "#registrationForm",
  primaryActionText = "Đăng ký ngay",
  // secondaryActionUrl = "#",
  // secondaryActionText = "Search Course"
}) => {
  return (
    <Container>
      <TwoColumn>
        <LeftColumn>
          <StyledHeader links={navLinks} collapseBreakpointClass="sm" />
          <Content>
            <Heading>{heading}</Heading>
            <Paragraph>{description}</Paragraph>
            {schedules.map((item, index) => (
              <Title2 key={index}>{item}</Title2>
            ))}
            {deadlinetimes.map((time, index) => (
              <Title2 key={index}>{time}</Title2>
            ))}
            <Actions>
              <a href={primaryActionUrl} className="action primaryAction">
                {primaryActionText}
              </a>
            </Actions>
          </Content>
        </LeftColumn>
        <RightColumn>
          <ResponsiveVideoEmbed tw="lg:mt-56!" url={url}> </ResponsiveVideoEmbed>
          {/* <Title1 tw="text-main-2 mt-6">
            10 ngày Phản Xạ Bứt Phá với chủ đề Entertainment &nbsp;
          </Title1>
          <br />
          <Title1 tw="text-main-2">
            Thời gian diễn ra: từ 18/6 - 27/6&nbsp;
          </Title1>
          <Title1 tw="text-main-1">
            (3 khung giờ linh động như thời gian học thử)
          </Title1>
          <br />
          <Title1 tw="text-main-2">
            Giá ưu đãi khi đăng ký trước 17/6 : 600.000
          </Title1>
          <p>(Áp dụng khi đăng ký từ 31/5 - 2/6)</p> */}
        </RightColumn>
      </TwoColumn>
    </Container>
  );
};
