export const getConfig = () => ({
  backend: process.env.REACT_APP_URL_BACKEND || "",
  basePath:
    process.env.REACT_APP_URL_BACKEND === "/"
      ? ""
      : process.env.REACT_APP_URL_BACKEND,
});

const { backend, basePath } = getConfig();

console.log({ backend, basePath });
