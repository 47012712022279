import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import {
  Container as ContainerBase,
  ContentWithPaddingXl as ContentBase,
} from "components/misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "images/checkbox-circle.svg";

const Container = tw(ContainerBase)`bg-main-2 text-gray-100 -mx-8 px-8`;
const ContentWithPaddingXl = tw(
  ContentBase
)`relative z-10 mx-auto px-0 py-10 sm:px-6 md:px-8 lg:px-12 xl:px-24 sm:py-20 flex flex-col max-w-screen-xl`;
const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-gray-300 text-center`;

const PlansContainer = tw.div`mt-16 flex flex-col items-center lg:flex-row lg:items-stretch lg:justify-between text-gray-900 font-medium`;
const Plan = styled.div`
  ${tw`w-full max-w-sm bg-white rounded-lg shadow-sm py-10 px-6 sm:px-10 lg:px-6 lg:py-10 xl:p-10 mx-3 flex flex-col justify-between mt-16 first:mt-0 lg:mt-0 shadow-raised`}
`;

const PlanHeader = styled.div`
  .nameAndFeaturedContainer {
    ${tw`flex flex-wrap flex-col sm:flex-row justify-between items-center`}
  }
  .name {
    ${tw`lg:text-lg font-bold uppercase tracking-wider mr-3`}
  }
  .featuredText {
    ${tw`text-xs font-bold px-3 rounded py-2 uppercase bg-green-300 text-green-900 leading-none mt-4 sm:mt-0 w-full sm:w-auto text-center`}
  }
  .pricingContainer {
    ${tw`mt-6 flex items-end justify-between`}
    .currentPrice {
      ${tw`text-lg font-bold leading-none`}
      .bigText {
        ${tw`text-3xl font-bold`}
      }
    }
    .oldPrice {
      ${tw`text-gray-500 text-lg line-through hidden sm:block`}
    }
  }
  .description {
    ${tw`mt-8 font-medium text-gray-700 lg:text-sm xl:text-base`}
  }
`;
const PlanFeatures = styled.ul`
  ${tw`mt-10 flex-1 border-t lg:-mx-6 -mx-6 sm:-mx-10 py-10 px-6 sm:px-10 lg:p-6 xl:-mx-10 xl:p-10`}
  .feature {
    ${tw`flex items-start mt-6 first:mt-0`}
    .icon {
      ${tw`w-6 h-6 text-main-2 flex-shrink-0`}
    }
    .text {
      ${tw`font-semibold text-main-2 tracking-wide ml-3`}
    }
  }
`;

const PlanAction = tw.div`mt-4`;
const ActionButton = styled(PrimaryButtonBase)`
  ${tw`block text-center text-sm font-semibold tracking-wider w-full text-gray-100 bg-main-2 px-6 py-4 rounded hover:bg-primary-700 focus:shadow-outline focus:outline-none transition-colors duration-300`}
`;

const WhiteBackgroundOverlay = tw.div`absolute inset-x-0 bottom-0 h-1/6 lg:h-1/3 bg-white z-0`;

export default ({
  subheading = "",
  heading = "Sự khác biệt giữa các khóa học",
  description = "",
  plans = null,
  primaryButtonText = "Tìm hiểu thêm",
}) => {
  const defaultPlans = [
    {
      name: "Chuyển Kênh Phản Xạ",
      price: ["2.500.000", "/ 1 tháng"],
      oldPrice: "",
      description:
        "Mất gốc hoặc đã có từ vựng cơ bản; Mong muốn cải thiện giao tiếp, phản xạ Tiếng Anh",
      features: [
        "1 tháng lớp học trực tiếp với giáo viên",
        "Thứ 2 - thứ 6: 5h30 - 6h00 / 6h10 - 6h40 / 20h30 - 21h00 / 21h30 - 22h00; Thứ 7: 21h00 - 21h30",
        "Dưới 15 học viên",
        "8 chủ đề",
        "120+ cụm từ B1, B2",
        "10 - 50 giờ thực hành",
        "Giáo viên: Nước ngoài + Việt Nam",
      ],
      url: "https://mysheo.com/collections/all/products/goi-chuyen-kenh-phan-xa",
      // featured: "Tăng tốc"
    },
    {
      name: "Chuyển Kênh Phản Xạ",
      price: ["7.500.000", "/ 3 tháng"],
      oldPrice: "",
      description:
      "Mất gốc hoặc đã có từ vựng cơ bản; Mong muốn cải thiện giao tiếp, phản xạ Tiếng Anh",
      features: [
        "3 tháng lớp học trực tiếp với giáo viên",
        "Thứ 2 - thứ 6: 5h30 - 6h00 / 6h10 - 6h40 / 20h30 - 21h00 / 21h30 - 22h00; Thứ 7: 21h00 - 21h30",
        "Dưới 15 học viên",
        "24 chủ đề",
        "400+ cụm từ B1, B2",
        "80 giờ thực hành",
        "Giáo viên: Nước ngoài + Việt Nam",
      ],
      url: "https://mysheo.com/collections/all/products/goi-chuyen-kenh-phan-xa",
      // featured: "Tăng tốc"
    },
    {
      name: "Tự Tin Phản Xạ",
      price: ["9.000.000", "/ 4 tháng"],
      oldPrice: "",
      description:
        "Học viên có mục tiêu cụ thể để chuyển việc hoặc thay đổi môi trường sống.",
      features: [
        "3 tháng Chuyển Kênh Phản Xạ",
        "8 buổi 1-1 45 phút theo nhu cầu học viên",
        "1 buổi coaching 1-1 với Hương Mysheo",
      ],
      url: "https://mysheo.com/collections/all/products/tu-tin-phan-xa-1",
      // featured: "Tăng tốc"
    },
  ];

  if (!plans) plans = defaultPlans;

  return (
    <Container id="pricing">
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              <PlanHeader>
                <span className="nameAndFeaturedContainer">
                  <span className="name" tw="whitespace-nowrap">{plan.name}</span>
                  {plan.featured && (
                    <span className="featuredText">{plan.featured}</span>
                  )}
                </span>
                <div className="pricingContainer">
                  <span className="currentPrice">
                    <span className="bigText">{plan.price[0]}</span>
                    {plan.price[1]}{" "}
                  </span>
                  {plan.oldPrice && (
                    <span className="oldPrice">{plan.oldPrice}</span>
                  )}
                </div>
                <p className="description">{plan.description}</p>
              </PlanHeader>
              <PlanFeatures>
                {plan.features.map((feature, index) => (
                  <li className="feature" key={index}>
                    <CheckboxIcon className="icon" />
                    <span className="text">{feature}</span>
                  </li>
                ))}
              </PlanFeatures>

              <PlanAction>
                <ActionButton
                  as="a"
                  href={plan.url ? plan.url : "#registrationForm"}
                >
                  {plan.url ? primaryButtonText : "Đăng ký ngay"}
                </ActionButton>
              </PlanAction>
            </Plan>
          ))}
        </PlansContainer>
      </ContentWithPaddingXl>
      <WhiteBackgroundOverlay />
    </Container>
  );
};
